//<div id="burger" class="burger clearfix">menu
//	<div class="line1"></div>
//</div>

$outerWidth: 50px;
$outerHeight: 50px;

$lineHeight: 4px;
$lineWidth: 27px;
$gap: 4px;
$radius: 4px;

$topLineColour: $blue;
$topLineColourOpen: $blue;

$middleLineColour: $blue;
$middleLineColourOpen: transparent;

$bottomLineColour: $blue;
$bottomLineColourOpen: $blue;

//@media (max-width: $b2){
//
//	.main-wrapper{
//
//		section{
//			overflow: hidden;
//			max-width: 375px;
//		}
//	}
//}

.burger{
	height: $outerHeight;
	width: $outerWidth;
	border-radius: $outerWidth;
	line-height: $outerHeight;
	padding: 0;
	-webkit-perspective: 900000px;
	perspective: 900000px;
	z-index: 112;
	pointer-events: auto;
	position: relative;
	display: none;
	transition: $time;
	margin-bottom: 25px;
	background-color: $grey-light;
	box-shadow: inset 0 0 0 4px $purple;

	@media (max-width: $b2){
		display: block;
		margin-bottom: 0;
	}

	&:hover{
		cursor: pointer;
	}

	.line1{
		width: $lineWidth;
		max-width: 100%;
		height: $lineHeight;
		background-color: $middleLineColour;
		position: absolute;
		top: (($outerHeight / 2) - ($lineHeight / 2));
		left: 50%;
		border-radius: $radius;
		transform: translateX(-50%);
		background-position: center center;

		.dark.dark.dark &{
			background-color: $grey;
		}

		html:not(.menu-open) .scrolled.scrolled.scrolled &,
		html:not(.menu-open) .light &{
			background-color: $white;
		}

		&:before,
		&:after{
			width: $lineWidth;
			max-width: 100%;
			height: $lineHeight;
			position: absolute;
			content: '';
			left: 0px;
			border-radius: $radius;
			transform-origin: center center;
			background-position: center center;
			transition: $time;

			.dark.dark.dark &{
				background-color: $grey;
			}

			html:not(.menu-open) .scrolled.scrolled.scrolled &,
			html:not(.menu-open) .light &{
				background-color: $white;
			}
		}

		&:before{
			transform: translateY(-#{$gap + $lineHeight});
			background-color: $topLineColour;
		}

		&:after{
			transform: translateY(#{$gap + $lineHeight});
			background-color: $bottomLineColour;
		}
	}

	&.close{

		.line1{
			background-color: $middleLineColour;
			transition: visibility .1s .2s, background-color .1s .2s;

			&:before{
				transform: translateY(-#{$gap + $lineHeight});
				background-color: $topLineColour;
				visibility: visible;
				animation: before .5s linear;
				transition: all $time 0s, visibility .1s .25s, background-color .1s .25s;
			}

			&:after{
				transform: translateY(#{$gap + $lineHeight});
				background-color: $bottomLineColour;
				visibility: visible;
				animation: after .5s linear;
				transition: all $time 0s, visibility .1s .25s, background-color .1s .25s;
			}

			@keyframes before {
				0% {transform: rotate(-45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(-#{$gap + $lineHeight});}
			}

			@keyframes after {
				0% {transform: rotate(45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(#{$gap + $lineHeight});}
			}
		}
	}

	&.open{

		.line1{
			transform-origin: 50% 50%;
			transition: visibility .1s .25s, background-color .1s .25s;
			background-color: $middleLineColourOpen;

			.dark.dark.dark &{
				background-color: $middleLineColourOpen;
			}

			&:before{
				transform: rotate(45deg) translateY(0);
				background-color: $topLineColourOpen;
				visibility: visible;
				animation: before-open .5s linear;
				transition: visibility .1s .25s, background-color .1s .25s;
			}

			&:after{
				transform: rotate(-45deg) translateY(0);
				background-color: $bottomLineColourOpen;
				visibility: visible;
				animation: after-open .5s linear;
				transition: visibility .1s .25s, background-color .1s .25s;
			}

			@keyframes before-open {
				0% {transform: translateY(-#{$gap + $lineHeight});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(45deg) translateY(0);}
			}

			@keyframes after-open {
				0% {transform: translateY(#{$gap + $lineHeight});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(-45deg) translateY(0);}
			}
		}
	}
}
