.woocommerce-page{

	.main-wrapper{

		// ::-webkit-input-placeholder {
		// 	@include font-size(24);

		// 	@media (max-width: $b2){
		// 		@include font-size(20);
		// 	}

		// 	@media (max-width: $b3){
		// 		@include font-size(16);
		// 	}
		// }

		// :-moz-placeholder { /* Firefox 18- */
		// 	@include font-size(24);

		// 	@media (max-width: $b2){
		// 		@include font-size(20);
		// 	}

		// 	@media (max-width: $b3){
		// 		@include font-size(16);
		// 	}
		// }

		// ::-moz-placeholder {  /* Firefox 19+ */
		// 	@include font-size(24);

		// 	@media (max-width: $b2){
		// 		@include font-size(20);
		// 	}

		// 	@media (max-width: $b3){
		// 		@include font-size(16);
		// 	}
		// }

		// :-ms-input-placeholder {
		// 	@include font-size(24);

		// 	@media (max-width: $b2){
		// 		@include font-size(20);
		// 	}

		// 	@media (max-width: $b3){
		// 		@include font-size(16);
		// 	}
		// }

		// input,
		// textarea{
		// 	background-color: #f4f4f4;
		// 	border-color: #f4f4f4;
		// 	@include font-size(24);
		// 	color: $black;

		// 	@media (max-width: $b2){
		// 		@include font-size(20);
		// 	}

		// 	@media (max-width: $b3){
		// 		@include font-size(16);
		// 	}
		// }

		// input[type="radio"],
		// input[type="checkbox"]{
		// 	display: none;
		// 	border: none!important;
		// 	margin: 0!important;

		// 	& + span,
		// 	& + .wpcf7-list-item-label,
		// 	& + label{
		// 		padding-left: 49px!important;
		// 		@include font-size(24);
		// 		line-height: 39px!important;

		// 		@media (max-width: $b2){
		// 			@include font-size(20);
		// 		}

		// 		@media (max-width: $b3){
		// 			@include font-size(16);
		// 		}

		// 		&:before{
		// 			width: 39px;
		// 			height: 39px;
		// 			left: 0;
		// 			top: 0;
		// 			border: 1px solid #f4f4f4;
		// 			background-color: #f4f4f4;
		// 		}

		// 		&:after{
		// 			width: 27px;
		// 			height: 27px;
		// 			left: 6px;
		// 			top: 6px;
		// 		}
		// 	}
		// }
	}
}

.woocommerce-notices-wrapper{
	width: 100%;
}

.woocommerce.woocommerce{

	.woocommerce-info{
		&:before{
			display: none;
		}

		padding-left: 20.5px;

		@include font-size(24);
	}

	.woocommerce-breadcrumb{
		max-width: $base-width + $grid-gutter-width;
		margin: 0 auto;
		padding: 36px 25px;
		@include font-size(16);
		color: #666666;

		@media (max-width: $b2){
			@include font-size(14);
		}

		@media (max-width: $b3){
			@include font-size(12);
		}

		a{
			color: $pink;
			text-decoration: underline;
		}

		span{
			margin-right: .8em;
			margin-left: .8em;
		}
	}

	.woocommerce-con{
		border-top: 5px solid $purple;
	}

	.woocommerce-notices-wrapper{

		.woocommerce-message{
			max-width: $base-width + $grid-gutter-width;
			margin: 0 auto;

		}
	}
}

.input-text{
	padding-top: 8.75px;
	padding-bottom: 8.75px;
}

.woocommerce{
	.woocommerce-MyAccount-content{
		a{
			@include font-size(22);
		}
	}
}

.woocommerce.woocommerce.woocommerce.woocommerce.woocommerce.woocommerce,
.woocommerce-page.woocommerce-page.woocommerce-page.woocommerce-page.woocommerce-page.woocommerce-page{

	h3{
		@include font-size(22);

		@media (max-width: $b2){
			@include font-size(18);
		}
	}

	.lost_reset_password{
		width: 100%;
	}

	div.woocommerce{
		max-width: $base-width + $grid-gutter-width;
		padding-right: $grid-gutter-width/2;
		padding-left: $grid-gutter-width/2;
		margin: 0 auto;
		padding-top: 0px;
		padding-bottom: 0px;

		@media (max-width: $b2){
			padding-right: 15px;
			padding-left: 15px;
		}

		@media (max-width: $b3){
			padding-top: 46px;
			padding-bottom: 46px;
		}
	}

	.form-row{
		margin-bottom: 0;
		padding: 0;

		input.input-text,
		textarea{
			margin-bottom: $input-bottom-margin;
		}

		label{
			line-height: 1.687;
		}
	}

	form .form-row-first,
	form .form-row-last,
	form .form-row-first,
	form .form-row-last{

		@media (max-width: $b4){
			width: 100%;
		}
	}

	.woocommerce-form-login{

		.form-row{
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.password-input{
				width: 100%;
			}
		}
	}

	.woocommerce-password-hint{
		margin-bottom: $input-bottom-margin;
	}

	[for=mailchimp_woocommerce_newsletter][for=mailchimp_woocommerce_newsletter][for=mailchimp_woocommerce_newsletter]{
		line-height: 1.3;
	}

	[for=mailchimp_woocommerce_newsletter],
	.woocommerce-form-login__rememberme{
		margin-bottom: $input-bottom-margin;
		display: inline-block;

		span{
			margin-bottom: 0px!important;
		}
	}

	[type="submit"]{
		margin-bottom: $input-bottom-margin;
	}

	form .form-row .required{
		color: $purple;
	}

	form .show-password-input{
		top: .9em;
	}

	form .show-password-input.display-password::after,
	form .show-password-input.display-password::after{
		color: $red;
	}

	form.checkout_coupon,
	form.login,
	form.register{
		border: none;
		padding: 0;
		margin-top: 0;
	}

	form .form-row input.input-text,
	form .form-row textarea{
		line-height: 1.2932em;
	}

	.select2-dropdown{
		max-width: none;
		border: 2px solid $grey-light;
		border-radius: 0;
		top: calc(100% - 2px);
		box-shadow: none;
		background-color: white;

		&.select2-dropdown--above{
			top: calc(100% - -2px);
		}

		.select2-results{
			padding: 0 21px 10.25px;
		}

		ul{

			li{
				margin-bottom: 5px;
				padding: 0;
				line-height: 1.5;
			}
		}

		.select2-search__field{

		}

		.select2-search{
			padding: 10.25px 21px 0;

			@media (max-width: $b2){
				padding: 6.25px 21px 0;
			}
		}

		.select2-results__option[data-selected=true]{
			background-color: white;
		}

		.select2-results__option--highlighted[data-selected]{
			color: $pink;
			background-color: white;
			transition: $time;
		}

		.select2-search__field{
			margin-bottom: 0;
			border: 2px solid $grey-light;
			padding: 10.25px 21px;

			@media (max-width: $b2){
				padding: 6.25px 21px;
			}
		}
	}

	form .select2-container{
		margin-bottom: $input-bottom-margin;
		height: 42.45px;
		border: 2px solid $grey-light;
		background-color: white;
		border-radius: 0px;

		@media (max-width: $b2){
			height: 38.48px;
		}

		&--open{
			border: 2px solid $grey-light;

			.select2-selection__arrow.select2-selection__arrow{
				background-image: url(../images/select-icon.svg);
			}
		}

		&--focus{
			border: 2px solid $grey-light;
		}

		.select2-selection--single.select2-selection--single{
			border: none;
			background-color: transparent;
		}

		.select2-selection--single{
			height: 42.45px;
			border: 2px solid $grey-light;
			background-color: white;
			border-radius: 0;
			line-height: 42.45px;

			@media (max-width: $b2){
				height: 38.48px;
				line-height: 38.48px;
			}
		}

		.select2-selection__rendered{
			height: 42.45px;
			line-height: 42.45px;
			padding-right: 50px;
			padding-left: 21px;
			color: $purple;

			@media (max-width: $b2){
				height: 38.48px;
				line-height: 38.48px;
			}
		}

		.select2-selection__arrow{
			height: 38.45px;
			background-image: url(../images/select-icon.svg);
			background-repeat: no-repeat;
			background-position: 100% 50%;
			width: 44px;
			top: 0;
			right: 0;

			@media (max-width: $b2){
				height: calc(38.48px - 4px);
			}

			b{
				display: none;
			}
		}
	}

	#ship-to-different-address{
		
		@media (min-width: $b3 + 1){
			padding-bottom: 3px;
		}

		span{
			margin-bottom: 0;
		}
	}

	.woocommerce-terms-and-conditions-checkbox-text.woocommerce-terms-and-conditions-checkbox-text.woocommerce-terms-and-conditions-checkbox-text{

		&:after{
			content: ''!important;
			margin: 0!important;
		}
	}

	.woocommerce-button{

		+ .woocommerce-button{
			margin-left: 4px;
		}
	}

	#respond input#submit,
	a.button,
	button.button,
	input.button{
		@extend %btn;
		min-width: 103px;
		padding: 9px 27px;

		background-color: $purple;
		border-color: $purple;
		color: $white!important;

		&:hover,
		&:focus-visible{
			background-color: transparent;
			border-color: $purple;
			color: $purple!important;
		}

		@media (max-width: $b2){
			padding-right: 7px;
			padding-left: 7px;
			//font-size: 16px !important;
			font-size: 1.6rem ;
		}

		&:disabled{
			padding: 9px 27px;
			pointer-events: none;

			@media (max-width: $b2){
				padding-right: 7px;
				padding-left: 7px;
				font-size: 1.6rem;
			}
		}
	}

	legend{
		padding-left: 10px;
		padding-right: 10px;
		margin-left: -10px;
	}

	fieldset{
		margin: 0 0 20px;
		padding: 18px 18px;
		border-color: $pink;
		border-style: solid;
		border-width: 1px;
	}

	.woocommerce-Address-title{
		display: flex;
		position: relative;
		margin-bottom: 12px;

		h3{
			width: 100%;
			padding-right: 50px;
			margin-bottom: 0;
		}

		.edit{
			position: absolute;
			bottom: .25em;
			right: 0;

			&:hover,
			&:focus-visible{
				color: $pink;
			}
		}
	}

	a.remove{
		color: $red!important;
		font-weight: 400;
		line-height: 28px;

		&:hover,
		&:focus-visible{
			background-color: $red;
			color: $white!important;
		}
	}

	.coupon.coupon.coupon{
		display: flex;

		@media (max-width: $b3){
			flex-wrap: wrap;
			justify-content: flex-end;
		}

		input,
		.button{
			margin-bottom: 10px;
		}

		input{
			min-width: 350px;
			margin-right: 15px;

			@media (max-width: $b3){
				min-width: 0;
				width: 100%;
				margin-right: 0;
			}
		}

		.button{
			padding: 6.5px 20px;
			width: auto;

			@media (max-width: $b3){
				padding: 6.5px 15px;
			}

			@media (max-width: $b2) {
				padding-right: 7px;
				padding-left: 7px;
				font-size: 1.6rem;
			}
		}
	}

	table.shop_table td{

		@media (max-width: $b3){
			padding: 6px 8px;
		}
	}

	table{

		tbody{

			*{
				font-weight: 400;
			}
		}
	}

	.cart_totals{

		table{

			td{
				width: 360px!important;

				@media (max-width: $b3 + 1){
					width: 100%!important;
				}
			}

			.order-total .woocommerce-Price-amount{
				font-weight: 700;
			}
		}
	}

	table{
		@include font-size(19.8425);

		@media (max-width: $b2){
			@include font-size(15);
		}

		tr{
			border-bottom: none!important;
		}

		td,
		th{
			@include font-size(19.8425);
			padding: 9px 10px;

			@media (max-width: $b2){
				@include font-size(15);
			}
		}

		small{
			color: #b1b1b1;
			@include font-size(16);
			line-height: 1.4;
			display: block;

			*{
				@include font-size(16);
				line-height: 1.4;
			}
		}

		input[type="radio"] + span, 
		input[type="radio"] + .wpcf7-list-item-label, 
		input[type="radio"] + label, 
		input[type="checkbox"] + span, 
		input[type="checkbox"] + .wpcf7-list-item-label, 
		input[type="checkbox"] + label{
			display: inline-block!important;
		}

		.product-subtotal{
			font-weight: 700;
		}

		&.woocommerce-cart-form__contents{


			@media (max-width: $b3){

				tbody{

					tr:first-of-type{

						td:first-of-type{
							border-top: none!important;
						}
					}
				}
			}
		}

		.actions{

			@media (max-width: $b3){
				padding-left: 0;
				padding-right: 0;
			}

			.button{
				width: auto;
				padding: 6.5px 20px;

				&:disabled{

					&:hover,
					&:focus-visible{
						background-color: $grey;
						border-color: $grey;
						color: $red!important;
					}
				}
			}
		}

		.cart_item{

			@media (max-width: $b3){
				padding: 0;
			}
		}

		.product-remove{

			@media (max-width: $b3){
				padding-bottom: 0;
			}
		}

		.product-thumbnail + .product-name{

			@media (max-width: $b3){
				border-top: none!important;
			}
		}

		img{
			width: 90px;
			min-width: 90px;
		}

		.product-remove{
			width: 45px !important;

			@media (max-width: $b3 + 1){
				width: 100%!important;
			}
		}

		.product-thumbnail,
		.product-price,
		.product-quantity,
		.product-subtotal{
			width: 50px!important;

			@media (max-width: $b3 + 1){
				width: 100%!important;
			}
		}

		.product-quantity{

			@media (max-width: $b3 + 1){
				display: flex;
				align-items: center;
				justify-content: space-between;

			}
		}

		// input{
		// 	border-bottom: 1px solid $grey-light!important;

		// 	&:focus-visible{
		// 		border-bottom: 1px solid $red!important;
		// 	}
		// }

		.screen-reader-text{
			height: 0;
			width: 0;
		}

		&.shop_table{

			th,
			td{
				border-top: 1px solid $grey-light;
				background-color: transparent;
			}
		}

		.shipping-calculator-button{
			white-space: pre;
			margin-top: 0;
		}

		.product-name{

			a{
				color: $colour;
				text-decoration: none;

				&:hover,
				&:focus-visible{
					color: $pink;
				}
			}
		}
	}

	.cart-collaterals{

		h2{
			font-weight: 700;
			color: $colour;
			@include font-size(42);

			@media (max-width: $b2){
				@include font-size(30);
			}
		}
	}

	.shop_table{

		// td:last-of-type{
		// 	width: 50px!important;
		// 	width: 210px!important;

		// 	label:before{
		// 		margin-top: 2px;
		// 	}

		// 	@media (max-width: $b3 + 1){
		// 		width: 100%!important;

		// 		&.product-total{
		// 			width: 50px!important;
		// 			width: 210px!important;

		// 			label:before{
		// 				margin-top: 2px;
		// 			}
		// 		}
		// 	}
		// }
	}

	.woocommerce ul#shipping_method li{
		padding-left: 0;
	}

	.woocommerce-checkout-review-order-table{

		.cart-subtotal,
		.order-total{

			td:last-of-type{

				@media (max-width: $b3 + 1){
					width: 50px!important;

				}
			}
		}
	}

	.woocommerce-checkout{

		#createaccount{

			+ span:last-of-type{
				margin-bottom: 18px;
			}
		}

		#terms{

			+ span{

				&:after{
					content: '*';
					color: $pink;
					font-weight: 700;
					margin-left: .25em;
				}
			}

			~ span.required{
				display: none;
			}
		}

		h3{
			color: $colour;
			border-bottom: 2px solid $grey-light;
			padding-bottom: 10px;
		}

		h3#ship-to-different-address{
			float: none;

			label{
				margin-bottom: 0;
			}
		}

		.input-checkbox{
			display: none;
		}

		#order_review_heading{
			padding-top: 20px;
		}
	}

	form .form-row.woocommerce-validated .select2-container,
	form .form-row.woocommerce-validated input.input-text,
	form .form-row.woocommerce-validated select{
		border-color: $green;
	}

	form .form-row.woocommerce-invalid .select2-container,
	form .form-row.woocommerce-invalid input.input-text,
	form .form-row.woocommerce-invalid select{
		border-color: $red;
	}

	form .form-row.woocommerce-invalid label{
		color: $red;
	}

	.woocommerce-checkout-payment{
		background-color: transparent!important;

		.input-checkbox{
			display: none;
		}

		.place-order{
			padding: 20px 0 0!important;
		}

		.woocommerce-terms-and-conditions-wrapper{
			margin-bottom: 20px;
		}
	}

	tabel.order_details tbody{

		@media (max-width: $b3){

			.product-name{
				width: 100%!important;
			}
		}
	}

	.woocommerce-order{

		h2{
			font-weight: 700;
			color: $colour;
		}
	}

	.woocommerce-customer-details address{
		border: 1px solid $red;
		border-radius: 0;
		line-height: 1.4em;

		p{
			line-height: 1.5em;
		}
	}

	form .form-row-first,
	form .form-row-last,
	form .form-row-first,
	form .form-row-last{
		width: calc(50% - (#{$grid-gutter-width}/2));

		@media (max-width: 575px){
			width: 100%;
		}
	}

	.col2-set{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.col-1,
		.col-2{
			padding-left: 0;
			padding-right: 0;
			width: 50%;
			flex: 0 0 50%;
			max-width: 50%;

			@media (max-width: $b3){
				width: 100%;
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		@media (min-width: $b3 + 1){
			.col-1{
				padding-right: 25px;
			}

			.col-2{
				padding-left: 25px;
			}
		}
	}
}

.woocommerce-checkout{

	.woocommerce &{
		padding-bottom: 30px;
	}
}

.woocommerce-form-coupon-toggle{
	margin-bottom: 1em;
}

.woocommerce-account-fields.woocommerce-account-fields.woocommerce-account-fields.woocommerce-account-fields.woocommerce-account-fields .create-account .show-password-input{
	top: .4em!important;
}

.woocommerce-EditAccountForm #mailchimp_woocommerce_is_subscribed{
	display: none;

	+ label{
		line-height: 33px;
	}
}

#mailchimp_woocommerce_is_subscribed#mailchimp_woocommerce_is_subscribed#mailchimp_woocommerce_is_subscribed{
	
	+ label{
		margin-bottom: 2em;
	}
}

.woocommerce-EditAccountForm .woocommerce-form-row.woocommerce-form-row--wide.form-row.form-row-wide{
	font-size: 0;
}


.woocommerce-error.woocommerce-error,
.woocommerce-info.woocommerce-info,
.woocommerce-message.woocommerce-message{
	padding: 15px 10px 15px 50px;
	padding-right: $grid-gutter-width/2;
	border-top-width: 0;
	margin-top: 0;
	margin-bottom: 0px;
	line-height: 35px;
	background-color: white;
	@include font-size(18);
	line-height: 1.3;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 84px;

	@media (max-width: $b2){
		@include font-size(17);
	}

	@media (max-width: $b3){
		@include font-size(16);
	}

	&:before{
		top: 1.75em;
		left: $grid-gutter-width/2;
	}

	&:after{
		display: none;
	}

	.button{
		order: 2;
	}

	@media (max-width: $b3){

		.button{
			width: 100%;
		}
	}
}

.hint--left.hint--left:after, 
.hint--left.hint--left:before{
	max-width: none;
}

.woocommerce-info.woocommerce-info{
	color: $purple;
	font-weight: 700;
	justify-content: flex-start;

	a{
		margin-left: .3em;
		font-weight: 700;
	}

	.button.button.button.button.button.button.button.button.button{
		margin-left: .3em;
	}
}

.woocommerce-message.woocommerce-message{
	border-top-color: $green;

	&:before{
		color: $green;
	}
}

.woocommerce-error.woocommerce-error{
	border-top-color: $red;
	color: $red;

	*{
		color: $red;
	}

	&:before{
		color: $red;
	}
}

.woocommerce-info.woocommerce-info{
	border-top-color: $green;

	&:before{
		color: $green;
	}
}

.woocommerce-view-order{

	@media (max-width: $b3){

		.product-name.product-name.product-name.product-name.product-name{
			width: 100%!important;
		}
	}
}

.page-id-34{

	.woocommerce.woocommerce.woocommerce{
		padding-bottom: 100px!important;
		padding-top: 46px!important;
	}

	#add_payment_method #payment{
		padding: 15px;
	}

	&.woocommerce-edit-address{

		.woocommerce-MyAccount-content.woocommerce-MyAccount-content.woocommerce-MyAccount-content{
			padding-top: 2px;
		}
	}

	&.woocommerce-edit-account{

		.woocommerce-MyAccount-content.woocommerce-MyAccount-content.woocommerce-MyAccount-content{
			padding-top: 0;
		}
	}

	.woocommerce{
		display: flex;
		flex-wrap: wrap;

		.woocommerce-MyAccount-navigation{
			width: 300px;

			@media (max-width: $b2){
				width: 200px;
			}

			@media (max-width: $b3){
				width: 100%;
				margin-bottom: 1em;
			}

			ul{

				@media (max-width: $b3){
					margin-bottom: 0;
					border-bottom: 1px solid $grey-light;
					display: flex;
					flex-direction: column;
				}

				&.open{

					li.is-active{
						background-image: url(../images/arrow-up.svg);
					}

					li:not(.is-active){
						display: block;
					}
				}

			}

			li{
				padding-left: 0;
				line-height: 0;

				@media (max-width: $b3){

					&:nth-of-type(1){
						order: 2;
					}

					&:nth-of-type(2){
						order: 3;
					}

					&:nth-of-type(3){
						order: 4;
					}

					&:nth-of-type(4){
						order: 5;
					}

					&:nth-of-type(5){
						order: 6;
					}

					&:nth-of-type(6){
						order: 7;
					}

					&:nth-of-type(7){
						order: 8;
					}

					&:nth-of-type(8){
						order: 9;
					}

					&:nth-of-type(9){
						order: 10;
					}

					&:nth-of-type(10){
						order: 11;
					}

					&:nth-of-type(11){
						order: 12;
					}

					&.is-active{
						padding-right: 13px;
						background-image: url(../images/arrow-down.svg);
						background-repeat: no-repeat;
						background-position: 100% 11px;
						cursor: pointer;

						a{
							pointer-events: none;
						}
					}

					&:not(.is-active){
						display: none;
					}
				}

				&.is-active{

					@media (max-width: $b3){
						order: 1;
					}

					a{
						padding-left: 13px;
						background-position: 0 .8em;
						background-position: 0 55%;
						color: $pink;
					}
				}

				a{
					text-decoration: none;
					line-height: 1.2em;
					display: inline-block;
					background-image: url(../images/arrow-right.svg);
					background-repeat: no-repeat;
					overflow: hidden;
					background-position: -10px 55%;
					background-size: 9px auto;
					padding: 2px 0;

					@include font-size(20);

					@media (max-width: $b3){
						line-height: 1.5;
					}

					&:hover,
					&:focus-visible{
						padding-left: 13px;
						background-position: 0 .8em;
						background-position: 0 55%;
					}
				}
			}

			+ .woocommerce-MyAccount-content{
				width: calc(100% - 300px);

				@media (max-width: $b2){
					width: calc(100% - 200px);

					p{
						line-height: 1.5;
					}
				}

				@media (min-width: $b3 + 1){
					padding-left: $grid-gutter-width;
				}

				@media (max-width: $b3){
					width: 100%;
				}
			}
		}
	}
}

p#account_password_field * {
	color: unset!important;
	border-color: unset!important;
}

