//!!MENU
header[role="banner"]{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: relative;
	top: 0;
	left: 0;
	transition: $time;
	background-color: transparent;

	// .home &{
	// 	background-color: transparent;
	// }

	@media (max-width: $b2){
		z-index: 111;
		margin-bottom: -1px;
	}

	// .scroll-down &,
	// .scroll-up &{

	// 	ul.main-menu.main-menu li > a{
	// 		color: $colour;

	// 		@media (max-width: $b2){
	// 			color: white;
	// 		}
	// 	}
	// }

	// .scroll-down &{
	// 	transform: translateY(-100%);
	// 	pointer-events: none;
	// }

	// .scroll-up &{
	// 	transform: translateY(0%);
	// 	background-color: white;
	// 	box-shadow: 0 10px 15px rgba(black, .1);

	// 	@media (min-width: $b2 + 1){

	// 		#site-logo{
	// 			margin-top: 30.89px;
	// 			margin-bottom: 30.89px;
	// 			width: 79px;
	// 			overflow: hidden;

	// 			img{
	// 				max-width: 280px;
	// 				width: 280px;
	// 			}
	// 		}
	// 	}
	// }
}

html:not(.menu-open) .scrolled.scrolled.scrolled{

	@media (min-width: $b2 + 1){

		#site-logo{
			margin-top: 22.54px;
			margin-bottom: 22.54px;
		}
	}

	.menu-top-outer{
		background-color: $grey;
	}
}

#site-logo{
	display: block;
	height: auto;
	max-width: 100%;
	position: absolute;
	left: 50%;
	top: -22px;
	transform: translateX(-50%);
	margin: 0;
	transition: $time;
	z-index: 1;
	width: 560.1622px;

	@media (max-width: 1600px){
		width: 286.1145px;
		top: 0;
	}

	@media (max-width: $b2){
		width: 286.1145px;
		position: static;
		transform: none;
		max-width: calc(100% - 96px);
	}

	@media (max-width: 415px){
		max-width: 192px;
	}

	img{
		display: block;
		width: 100%;
	}
}

html:not(.menu-open) .scrolled,
html:not(.menu-open) .light{

	#site-logo{

		svg{

			*{
				fill: $white;
			}
		}
	}

	.social-menu a{
		color: white;
	}
}

.menu-top-outer{
	background-color: $grey;
	padding-top: 17px;
	padding-bottom: 17px;

	@media (max-width: $b2){
		padding-top: 16px;
		padding-bottom: 16px;
	}

	.col-12{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		@include font-size(24);
		font-weight: 700;
		font-family: $alt-font;

		@media (max-width: $b2){
			@include font-size(20);
		}
	}
}

.menu-middle-outer{
	padding-top: 78px;
	padding-bottom: 78px;

	@media (max-width: 1600px){
		padding-top: 20px;
		padding-bottom: 20px;
	}

	@media (max-width: $b2){
		padding-top: 12px;
		padding-bottom: 5px;
		border-bottom: 10px solid $purple;
	}

	.col-12{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.left-side,
	.right-side{
		width: 415px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		svg{
			margin: 0 auto;
		}
	}

	.left-side{
		justify-content: flex-start;
		align-items: flex-end;

		@media (max-width: $b2){
			display: none;
		}

		a{
			margin-bottom: .3em;

			@media (max-width: $b1){
				margin-bottom: 5.331px;
			}

			+ a{
				margin-left: 17px;

				@media (max-width: 1600px){
					margin-left: 15px;
				}
			}

			svg{

				@media (max-width: 1600px){
					width: 30px;
				}

				@media (max-width: $b2){
					width: 23px;
				}

				*{
					fill: $blue;
					transition: $time;
				}
			}

			&:hover,
			&:focus-visible{

				svg{

					*{
						fill: $blue-light;
						fill: lighten($blue, 12.5%);
						fill: $pink;
					}
				}
			}
		}

		span{
			@include font-size(23.8);
			font-weight: 700;
			display: block;
			width: 100%;
			color: $blue;

			@media (max-width: 1600px){
				@include font-size(17.771);
			}
		}
	}

	.right-side{
		justify-content: flex-end;
		align-items: baseline;

		@media (max-width: $b2){
			width: calc(100% + 20.5px + 20.5px);
			max-width: calc(100% + 20.5px + 20.5px);
			margin-right: -20.5px;
			margin-left: -20.5px;
			padding-right: 20.5px;
			padding-left: 20.5px;
			justify-content: center;
			border-top: 2px solid #cecece;
			margin-top: 11px;
			padding-top: 8px;
		}

		@media (max-width: $b3){
			width: calc(100% + 16px + 16px);
			max-width: calc(100% + 16px + 16px);
			margin-right: -16px;
			margin-left: -16px;
			padding-right: 16px;
			padding-left: 16px;
		}

		a{
			color: $purple;
			text-align: center;
			@include font-size(23.8);
			font-weight: 700;
			font-family: $alt-font;
			position: relative;

			@media (max-width: 1600px){
				@include font-size(17.771);
			}

			+ a{
				margin-left: 30px;

				@media (max-width: 1600px){
					margin-left: 15px;
				}

				@media (max-width: $b2){
					margin-left: 42px;
				}

				@media (max-width: 400px){
					margin-left: 16px;
				}
			}

			.cart-contents-count{
				position: absolute;
				top: 15px;
				left: 50%;
				transform: translateX(-50%);
				@include font-size(13);
				color: white;

				@media (max-width: 1600px){
					@include font-size(10);
				}

				@media (max-width: $b2){
					@include font-size(10);
					top: 6px;
					text-align: center;
				}
			}

			.cart-contents-total{
				position: absolute;
				top: 31px;
				left: 50%;
				transform: translateX(-50%);
				@include font-size(11);
				color: white;
				padding: 0px 4px;
				border-radius: 20px;
				background-color: $blue;
				text-align: center;
				max-width: none;

				@media (max-width: 1600px){
					@include font-size(9);
					top: 27px;
				}

				@media (max-width: $b2){
					@include font-size(8);
					top: 21.5px;
					line-height: 10px;

					*{
						padding: 0;
					}
				}
			}


			svg{
				margin-bottom: .3em;

				@media (max-width: 1600px){
					width: 30px;
				}

				@media (max-width: $b2){
					width: 23px;
				}

				*{
					fill: $purple;
					transition: $time;
				}
			}

			@media (min-width: $b2 + 1){

				&:hover,
				&:focus-visible{
					color: $blue;

					svg{

						*{
							fill: $blue;
						}
					}
				}
			}
		}
	}

	.search{
		position: relative;

		@media (max-width: $b2){
			position: static!important;
		}

		&.open{
			color: $blue;

			svg{

				*{
					fill: $blue;
				}
			}

			form{
				opacity: 1;
				visibility: visible;
			}
		}

		form{
			position: absolute;
			right: 0;
			top: calc(100% + 12px);
			width: 450px;
			max-width: 450px;
			border: 3px solid $purple;
			z-index: 22;
			background-color: white;
			padding: 10px 10px;
			display: flex;
			color: $purple;
			opacity: 0;
			visibility: hidden;

			@media (max-width: $b2){
				width: 100%;
				max-width: 100%;
			}

			[type="text"]{
				margin-right: 10px;
				margin-bottom: 0;
				width: calc(100% - 72px);

				@media (max-width: $b2){
					width: calc(100% - 60px);
				}
			}

			[type="submit"]{
				min-width: 0;
				width: 62px;
				height: 62px;
				padding: 0;
				margin: 0!important;

				@media (max-width: $b2){
					width: 50px;
					height: 50px;
				}

				svg{
					margin: 0;

					*{
						fill: $white;
					}
				}

				&:hover,
				&:focus-visible{

					svg{

						*{
							fill: $purple;
						}
					}
				}

			}
		}


	}
}

.menu-bottom-outer{
	padding-bottom: 41px;

	@media (max-width: $b2){
		padding-bottom: 0;
	}

	.col-12{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		position: static;
	}
}

.mob-only.mob-only{

	@media (min-width: 1300px + 1 ){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

@import 'burger';

#mainMenu{
	display: flex;
	align-items: center;
	position: static;
	width: 100%;
	justify-content: space-between;

	.hide-menu &{
		display: none;
	}

	@media (max-width: $b2){
		overflow: auto;
		height: 100vh;
		height: auto;
		position: fixed;
		max-height: calc(100vh - 213.31px);
		top: 100%;
		width: 100%;
		background-color: $white;
		left: 0;
		right: 0;
		display: none;
		z-index: 111;
		transition: $time;
		padding: 10px 15px 20px;
		text-align: center;
		border: none;
		border: 3px solid $purple;
		border-top: none;

		.menu-open &{
			display: block;
		}
	}

	@media (max-width: 415px){
		max-height: calc(100vh - 199.22px);
	}
}

#mainMenu .main-menu .current-menu-ancestor:not(.menu-item-object-custom),
#mainMenu .main-menu .current-page-ancestor:not(.menu-item-object-custom),
#mainMenu .main-menu .current-menu-parent,
#mainMenu .main-menu .current_page_parent:not(.menu-item-object-custom),
#mainMenu .main-menu .current-menu-item:not(.menu-item-object-custom){

	> a{
		color: $purple;
	}
}

ul.main-menu.main-menu{
	margin: 0;
	padding: 0;
	@include font-size(0);
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
	text-align: center;

	@media (min-width: $b2 + 1){

		&.mob{
			display: none;
		}
	}

	@media (max-width: $b2){
		flex-direction: column;
		position: static;
		margin-bottom: 0;
		overflow: hidden;
		margin-right: 0;
	}

	li{
		@include font-size(0);
		line-height: 1;
		position: static;

		@media (min-width: $b2 + 1){

			&:hover{

				> a{
					color: $purple;
				}
			}
		}

		&.btn-link{

			@media (min-width: $b2 + 1){

				&:hover,
				&:focus-visible{

					> a{
						color: $pink!important;
					}
				}
			}

			&.menu-item.menu-item.menu-item.menu-item.menu-item.menu-item.menu-item.menu-item.menu-item.menu-item.menu-item{

				> a{

					@media (max-width: 1600px){
						font-size: 17px !important;
						font-size: 1.7rem !important;
						min-width: 0px;
					}
				}
			}

			> a{
				@extend %btn;

				@media (max-width: 1600px){
					font-size: 17px !important;
					font-size: 1.7rem !important;
				}
			}

			@media (min-width: $b2 + 1){

				&.blue{

					&:hover,
					&:focus-visible{

						> a{
							color: $blue!important;
						}
					}

					> a{
						background-color: $blue;
						border-color: $blue;
						color: $white!important;

						&:hover,
						&:focus-visible{
							background-color: transparent;
							border-color: $blue;
							color: $blue!important;
						}
					}
				}
			}
		}

		li.menu-item.menu-item{
			width: 100%;
		}

		&.menu-item-has-children{

			@media (min-width: $b2 + 1){

				&:hover{

					> a{
						background-color: $white;
						color: $purple;
					}

					> ul{
						opacity: 1;
						visibility: visible;
						transition: $time;
						pointer-events: auto;
						z-index: 3;

						ul{
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}

			@media (max-width: $b2){
				padding-right: 50px;
				background-repeat: no-repeat;
				background-position: right 19px top 16px;
				position: relative;

				&:after,
				&:before{
					content: '';
					position: absolute;
					top: 13px;
					right: 0;
					left: auto;
					transform: translateY(-50%) rotate(0deg);
					width: 16px;
					height: 2px;
					background-color: $purple;
					transition: $time;
					display: block;
					border-radius: 2px;
				}

				&:before{
					transform: translateY(-50%) rotate(-90deg);
				}

				&.open{

					&:before{
						transform: translateY(-50%) rotate(-45deg);
					}

					&:after{
						transform: translateY(-50%) rotate(45deg);
					}

					> a{
						color: $purple;
					}

					.sub-menu{
						pointer-events: auto!important;
					}

					> .sub-menu{
						max-height: 1000px;
						position: relative;
						pointer-events: auto;

						&:after{
							content: '';
							position: absolute;
							left: 0;
							bottom: 0;
							width: 100%;
							max-width: 100%;
							opacity: .4;
							visibility: visible;
						}
					}
				}
			}

			.sub-menu{
				position: absolute;
				top: calc(100% - 41px);
				padding-top: 46px;
				padding-bottom: 10px;
				left: 0;
				right: 0%;
				background-color: transparent;
				opacity: 0;
				visibility: hidden;
				margin: 0;
				pointer-events: none;

				@media (min-width: $b2 + 1){
					position: absolute;
					display: flex;
					justify-content: center;
					width: 1763px;
					max-width: 100%;
					margin-left: 50%;
					transform: translateX(-50%);

					&:before{
						content: '';
						position: absolute;
						top: 41px;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
						width: 350vw;
						max-width: 350vw;
						background-color: white;
						border-top: 5px solid $purple;
						border-bottom: 10px solid $blue;
					}


					// > li{
					// 	text-align: center;

					// 	&:first-of-type{
					// 		margin-left: calc((100vw - #{$base-width})/2);
					// 	}

					// 	&:last-of-type{
					// 		margin-right: calc((100vw - #{$base-width})/2);
					// 	}
					// }
				}

				@media (max-width: $b2){
					position: static;
					opacity: 1;
					visibility: visible;
					background-color: transparent;
					transform: none;
					width: 100%;
					max-width: 100%;
					max-height: 0;
					overflow: hidden;
					padding-top: 0px;
					padding-bottom: 0px;
				}

				@media (min-width: $b2 + 1){

					.menu-item-has-children{
						//width: 322px;
						width: 33.3%;

						> a{
							//text-align: left;
						}
					}

					.sub-menu{
						position: static;
						display: block;
						padding: 0;
						pointer-events: auto;
						padding-bottom: 16px;
						margin-top: -14px;

						&:before{
							display: none;
						}

						li{

							a{
								font-weight: 400;
								padding: 6px 8px;
								//text-align: left;
							}
						}
					}
				}

				@media (max-width: $b2){

					li{

						&:before,
						&:after{
							display: none!important;
						}

						.sub-menu{
							max-height: 9999px;
							//padding-left: 15px;

							a{
								@include font-size(14);
							}
						}
					}


				}

				li{
					margin: 0;
					padding: 0;

					@media (max-width: $b2){
						padding: 0;

						&.open{

							.sub-menu{
								pointer-events: auto!important;
							}

							.sub-menu:after{
								display: none;
							}
						}
					}

					> a{

						@media (min-width: $b2 + 1){

							&:hover{
								color: $blue;
							}
						}
					}

					a{
						background-color: transparent;
						display: block;
						text-align: center;
						color: $purple;
						padding: 20px 8px;
						@include font-size(24);
						text-shadow: none!important;
						min-width: 0;
						min-height: 0;

						@media (max-width: 1600px){

							@media (min-width: $b2 + 1){
								@include font-size(17);
								min-height: 0px;
							}
						}

						@media (max-width: $b2){
							padding: 0 0;
							@include font-size(17);
							text-transform: none;
							text-align: left;
							font-weight: 400;

							&:after{
								display: none;
							}

						}
					}
				}
			}
		}

		> a{
			@include font-size(24);
			width: auto;
			color: $blue;
			line-height: 1.5em;
			text-decoration: none!important;
			font-weight: 700;
			position: relative;
			font-family: $alt-font;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			padding: 9px 5px;
			overflow: hidden;
			z-index: 2;
			min-height: 54px;

			@media (max-width: 1600px){

				@media (min-width: $b2 + 1){
					@include font-size(20);
				}
			}

			@media (min-width: $b2 + 1){

				&:hover,
				&:focus-visible{
					color: $purple;
				}
			}

			@media (max-width: $b2){
				@include font-size(24);
				padding: 0 0;
				min-height: 0;
				justify-content: flex-start;
			}
		}
	}
}
