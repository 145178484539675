//!!BUTTON
button:focus-visible,
.btn:focus-visible{
	outline: none;
}

.btn-con{
	display: flex;
	align-items: center;
	margin: 0 -12px;
	max-width: calc(100% + 12px + 12px);
	flex-wrap: wrap;

	@media (max-width: $b2){
		max-width: calc(100% + 7px + 7px);
		margin: 0 -7px;
	}

	.btn{
		margin: 0 12px 5px;

		@media (max-width: $b2){
			margin: 0 7px 5px;
		}
	}

	&.btn-con-center{
		justify-content: center;
	}

	&.btn-con-left{
		justify-content: flex-start;
	}

	&.btn-con-right{
		justify-content: flex-end;
	}
}

.btn, %btn{
	display: inline-flex;
	align-items: center;
	padding: 5px 5px;
	font-size: 24px!important;
	font-size: 2.4rem!important;
	text-decoration: none!important;
	line-height: 1.5em;
	border: 2px solid $pink;
	color: $white!important;
	font-weight: 700;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	box-shadow: none;
	font-family: $alt-font;
	position: relative;
	border-radius: 13px;
	transition: $time;
	background-color: $pink;
	min-width: 128.16px;
	justify-content: center;
	text-transform: uppercase;

	@media (max-width: $b2){
		border-radius: 7px;
	}
	
	&:hover,
	&:focus-visible{
		outline: none;
		background-color: transparent;
		border-color: $pink;
		color: $pink!important;
	}
}

.btn{

	&.blue{
		background-color: $blue;
		border-color: $blue;
		color: $white!important;

		&:hover,
		&:focus-visible{
			background-color: white;
			border-color: $blue;
			color: $blue!important;
		}
	}

	&.blue-light{
		background-color: $blue-light;
		border-color: $blue-light;
		color: $blue!important;

		&:hover,
		&:focus-visible{
			background-color: transparent;
			border-color: $blue;
			color: $blue!important;
		}
	}

	&.white{
		background-color: $white;
		border-color: $white;
		color: $pink!important;

		&:hover,
		&:focus-visible{
			background-color: transparent;
			border-color: $pink;
			color: $pink!important;
		}
	}

	&.white-blue{
		background-color: $white;
		border-color: $white;
		color: $blue!important;

		&:hover,
		&:focus-visible{
			background-color: transparent;
			border-color: $blue;
			color: $blue!important;
		}
	}

	&.purple{
		background-color: $purple;
		border-color: $purple;
		color: $white!important;

		&:hover,
		&:focus-visible{
			background-color: transparent;
			border-color: $purple;
			color: $purple!important;
		}
	}

	&.disabled,
	&:disabled{
		opacity: .5;

		&:hover,
		&:focus-visible{
			background-color: transparent;
			border-color: $pink;
			color: $pink!important;
		}
	}

	&.big{
		padding: 9px 17px;
		font-size: 28.1316px !important;
		font-size: 2.81316rem !important;
		text-transform: none;
		letter-spacing: .015em;

		@media (max-width: $b2){
			padding: 5px 9px;
			font-size: 15.4075px !important;
			font-size: 1.54075rem !important;
		}
	}

	&.huge{
		padding: 4px 17px;
		font-size: 34.1316px !important;
		font-size: 3.41316rem !important;
		text-transform: none;
		letter-spacing: -.01em;
		min-width: 527.027px;

		@media (max-width: $b2){
			padding: 2px 17px;
			font-size: 22px !important;
			font-size: 2.2rem !important;
			min-width: 340px;
		}

		@media (max-width: 400px){
			min-width: 280px;
		}
	}

	&.small{
		min-width: 50px;
		padding: 7px 15px;
	}
}