footer{
	position: relative;
	background-color: $white;
	background-position: 50% 0;

	*{
		color: $blue;
	}

	.footer-top-outer{
		padding: 120px 0 139px;


		@media (max-width: 1363px){

			.col-12:nth-of-type(1){
				text-align: center;
				flex: 0 0 100%;
				max-width: 100%;

				.tel{
					width: 100%;
				}
			}
		}

		@media (max-width: $b2){

			.col-12{
				text-align: center;
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		@media (max-width: $b2){
			padding: 40px 0 0px;
			text-align: center;
		}

		#footer-logo{
			display: block;
			height: auto;
			max-width: 100%;
			margin: 0 0 1.6em;
			transition: $time;
			z-index: 1;
			width: 399.0572px;
			z-index: 50;
			transition: 0s;

			@media (max-width: 1363px){
				width: 330px;
				margin: 0 auto 10px;
			}

			img{
				display: block;
				width: 100%;
			}
		}

		h3{
			@include font-size(28);
		}

		p{
			@include font-size(26);
			font-weight: 700;
			margin-bottom: .33em;

			@media (max-width: $b2){
				@include font-size(23);
				margin-bottom: .93em;
			}
		}

		.tel{
			@include font-size(30);
			font-weight: 700;
			font-family: $alt-font;
			margin-bottom: .75em;
			display: inline-block;

			@media (max-width: $b2){
				@include font-size(40);
				margin-bottom: .4em;
				display: block;
				line-height: 1em;
			}
		}

		.btn{
			min-width: 0;
		}

		h6{
			@include font-size(23.38);
			margin-bottom: .8em;

			@media (max-width: $b2){
				@include font-size(17);
				margin-bottom: .05em;
			}
		}

		h3{

			@media (max-width: $b2){
				@include font-size(40);
				margin-bottom: .3em;
			}
		}

		address{
			@include font-size(21.38);
			font-weight: 700;
			line-height: 1.75;

			@media (max-width: $b2){
				@include font-size(15);
				margin-bottom: 1.65em;
			}
		}

		h5{
			@include font-size(24);

			@media (max-width: 1620px){
				@include font-size(20);
			}
		}

		.footer-address{
			text-align: right;
		}

		.awards{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin: 0 -16px;
			width: calc(100% + 16px + 16px);
			max-width: calc(100% + 16px + 16px);

			@media (max-width: $b2){
				width: calc(100% + 16px + 16px);
				max-width: calc(100% + 16px + 16px);
			}

			img{
				margin: 0 16px 32px;
				width: calc(33.3% - 16px - 16px);

				@media (max-width: $b2){
					width: calc(33.3% - 13px - 13px);
					margin: 0 13px 16px;
				}
			}
		}

		.col-lg-4{

			@media (min-width: $b1 + 1){

				&:nth-of-type(1){
					flex: 0 0 30.93333%;
					max-width: 30.93333%;
				}

				&:nth-of-type(4){
					flex: 0 0 34.86667%;
					max-width: 34.86667%;
					padding-left: 30px;
				}
			}

			&:nth-of-type(4){
				padding-top: 101px;

				@media (max-width: $b2){
					padding-top: 0;
				}
			}
		}

		.col-lg-2{

			@media (min-width: $b1 + 1){

				&:nth-of-type(2),
				&:nth-of-type(3),{
					flex: 0 0 17.1%;
					max-width: 17.1%;
				}
			}


			@media (min-width: $b2 + 1){

				@media (max-width: 1363px){

					&:nth-of-type(2),
					&:nth-of-type(3),{
						flex: 0 0 33.33333%;
						max-width: 33.33333%;
					}
				}
			}

			&:nth-of-type(2),
			&:nth-of-type(3){
				padding-top: 97px;

				@media (max-width: $b2){
					padding-top: 28px;
				}
			}
		}

		.footer-menu{
			display: flex;
			flex-wrap: wrap;
			margin: 0 auto;
			@include font-size(20);
			line-height: 1.125;

			@media (max-width: 1620px){
				@include font-size(18);
			}

			li{
				@include font-size(20);
				width: 100%;

				@media (max-width: 1620px){
					@include font-size(18);
				}

				&:last-of-type{

					a{
						margin-bottom: .5em;
					}
				}

				a{
					@include font-size(20);
					line-height: 1.4;
					font-weight: 700;
					text-decoration: none;
					color: $blue;
					display: block;
					margin-bottom: .65em;

					@media (max-width: 1620px){
						@include font-size(18);
					}

					&:hover,
					&:focus-visible{
						color: $purple!important;
						text-decoration: none;
					}
				}
			}

			+ .btn{
				padding: 4px 10px;
				@include font-size(20);
				margin: 0 0 .69em;

				@media (max-width: $b2){
					margin: 0.4em 0 1.5em;
					padding: 3px 21px;
					@include font-size(18);
				}
			}
		}

		h5.mob-only{
			margin-top: 1em;
		}

		.mob-only{

			@media (min-width: $b2 + 1){
				display: none!important;
			}
		}

		.desk-only{

			@media (max-width: $b2){
				display: none;
			}
		}

		.social-menu.social-menu.social-menu{
			margin: 0;
			justify-content: flex-start;

			@media (max-width: $b2){
				margin: 0 auto;
				justify-content: center;
			}

			a{
				color: white;
				margin: 0 5.5px;
				line-height: 46px;
				height: 46px;
				min-width: 46px;
				@include font-size(30);

				&:hover,
				&:focus-visible{
					background-color: $purple;
					color: white!important;

					*{
						color: white!important;
					}
				}

				i{
					line-height: 46px;
					height: 46px;
					min-width: 46px;
					@include font-size(30);
				}

				&:first-of-type{
					margin-left: 0;
				}

				&:last-of-type{
					margin-right: 0;
				}

				*{
					color: white;
				}
			}
		}

	}

	.footer-bottom-outer{
		background-color: $grey;
		padding: 39px 0 29px;

		@media (max-width: $b2){
			padding: 16.5px 0 18.5px;
		}

		@media (max-width: $b2){

			.col-12{
				text-align: center;
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		.row{
			align-items: center;
		}

		img{
			mix-blend-mode: multiply;
			margin-bottom: 1em;
		}

		.copy{
			@include font-size(16);

			@media (max-width: $b2){
				@include font-size(14);
				text-align: center;
				margin-bottom: .9em;
			}
		}

		@media (max-width: $b2){

			.col-12{

				&:nth-of-type(1){
					display: flex;
					flex-direction: column;
					align-items: center;

					img{
						order: 2;
					}

				}
			}
		}

		.by{
			@include font-size(16);
			font-weight: 700;
			text-align: right;

			@media (max-width: $b2){
				@include font-size(14.9708);
				text-align: center;
			}

			a{
				font-weight: 700;
			}
		}
	}
}
