html{
	font-size: 62.5%;
	font-family: $main-font;
	font-weight: 400;
	margin-top: 0 !important;
	visibility: visible;
	overflow-x: hidden;

	&#BTT{
		margin-top: 0 !important;
	}

	@media (max-width: $b2){

		&.menu-open{
			overflow: hidden;
		}
	}

	// &.loaded{
	// 	//opacity: 1;
	// 	//visibility: visible;

	// 	body{
	// 		//overflow-y: visible;
	// 		//overflow: hidden;
	// 	}
	// }

	// @media (max-width: $b2){

	// 	&.search-open,
	// 	&.menu-open{
	// 		overflow: hidden;

	// 		body{
	// 			overflow: hidden;
	// 		}
	// 	}
	// }

	// &#backtotop{
	// 	margin-top: 0!important;
	// }
}

body{
	color: $colour;
	background-color: $main-bg-colour;
	@include font-size(29);
	@include line-height(29,20);
	line-height: 1.2;
	font-weight: 400;
	position: relative;
	overflow-x: hidden;

	@media (max-width: $b2){
		@include font-size(17);
		@include line-height(17,20.4);
	}

	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */

	*{

		&::-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
}

#wpadminbar{
	display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
address,
blockquote,
pre,
table,
li{
	color: $colour;
	margin-top: 0;
	font-weight: 400;
	margin-bottom: 1em;
	@include font-size(29);
	@include line-height(29,20);
	line-height: 1.2;

	@media (max-width: $b2){
		@include font-size(17);
		@include line-height(17,20.4);
	}

	sub,
	sup{
		font-size: 60%;
	}

	a{
		font-size: inherit;
		text-decoration: none;
		font-weight: 700;
		color: $colour;

		&:hover{
			color: $pink;
			text-decoration: none;
		}
	}

	strong{
		font-weight: 700;
		color: inherit;
	}

	em{
		font-style: normal;
		color: $colour;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
blockquote{
	color: $colour;
	font-family: $main-font;
	margin-bottom: .45em;
	font-weight: 700;

	em{
		font-style: normal;
		color: $colour;
		font-weight: 400;
	}
}

h1{
	@include font-size(69);
	@include line-height(69,82.8);

	@media (max-width: $b2){
		@include font-size(37);
	}
}

h2{
	@include font-size(57);
	@include line-height(57,68.4);

	@media (max-width: $b2){
		@include font-size(30);
	}
}

h3{
	@include font-size(22);

	@media (max-width: $b2){
		@include font-size(37);
	}
}

h4{
	@include font-size(28);
	@include line-height(28, 44.4);

	@media (max-width: $b2){
		@include font-size(30);
	}
}

h5{
	@include font-size(35.7703);
	@include line-height(35.7703,42.9244);
}

h6{
	@include font-size(33.7166);
	@include line-height(33.7166,40.4599);
}

p{
	@include font-size(22);
	@include line-height(29,20);
	line-height: 1.3;
	margin-bottom: 2.2em;

	@media (max-width: $b2){
		@include font-size(17);
		@include line-height(17,20.4);
	}
}

mark{
	background-color: $pink;
	font-weight: 700;
	box-shadow: .05em 0 0 $pink, -.05em 0 0 $pink;
}

strong{
	font-weight: 700;
}

blockquote{
	@include font-size(22);
	@include line-height(22,38);
	letter-spacing: normal;
	font-family: $main-font;
	margin: 0 auto .6em;
	font-weight: 700;
	position: relative;
	padding: 0;
	font-style: normal;
	color: $colour;

	@media (max-width: $b2){
		@include font-size(18.4708);
		@include line-height(18.4708,24.2031);
	}

	&:before{
		content: '"';
	}

	&:after{
		content: '"';
	}

	p{
		font-size: inherit;
		line-height: inherit;
		font-family: inherit;
		margin-bottom: 0!important;
	}

	+ .by{
		@include font-size(22);
		@include line-height(22,30);
		font-style: italic;
		font-weight: 600;

		@media (max-width: $b2){
			@include font-size(15.9231);
		}
	}
}

a{
	font-size: inherit;
	color: $colour;
	font-weight: 400;
	transition: $time;
	cursor: pointer;
	text-decoration: none;

	&:hover{
		color: $pink;
		text-decoration: none;
	}
}

hr{
	border: 0;
	width: 292.8877px;
	height: 25.541px;
	margin: 1.74em auto 1.74em;
	background-image: url(../images/hr.svg);
	background-repeat: no-repeat;
	background-position: 50% 50%;

	.white &{
		background-image: url(../images/hr-white.svg);
	}

	@media (max-width: $b2){
		margin: 1.7em auto 1.7em;
		width: 168.3361px;
		height: 17.1058px;
		background-size: 168.3361px auto;
	}
}

address{
	font-style: normal;
	@include font-size(22);
	color: $colour;
	font-weight: 400;
	margin-bottom: 2.1em;

	@media (max-width: $b2){
		@include font-size(17);
		@include line-height(17,20.4);
	}
}

ol,
ul,
dl{
	margin: 0;
	padding-left: 0;
	list-style-position: inside;
	@include font-size(22);
	margin-bottom: 14px;

	li,
	dt,
	dd{
		@include font-size(22);
		line-height: 1.3;
		margin-bottom: 0;

		@media (max-width: $b2){
			@include font-size(17);
			@include line-height(17,20.4);
		}

		a{
			font-size: inherit;

			&:hover{
				text-decoration: underline;
			}
		}
	}

	dd{
		margin-bottom: 20px;
	}
}

ul{
	list-style-position: inside;
	padding-left: 0;

	&.no-bullet{
		list-style: none;

		li{
			padding-left: 0;

			&:before{
				display: none;
			}
		}
	}

	ul{
		margin-top: 0.8em;

		li{

			&:before{
				background-color: transparent;
			}
		}
	}

	li{
		padding-left: 0.9em;
		list-style: none;
		position: relative;
		margin-bottom: 1.2em;

		&:before{
			content: '';
			position: absolute;
			left: .21em;
			top: .48em;
			width: .3em;
			height: .3em;
			border-radius: 24px;
			display: inline-block;
			background-color: $purple;
		}
	}
}

ul.wc_payment_methods{

	li{

		&:before{
			display: none!important;
		}
	}
}

ul.filter-items,
ul.woocommerce-PaymentMethods,
ul.woocommerce-shipping-methods,
ul.woocommerce-order-overview,
ul.wc_payment_methods,
ul.select2-results__options,
.woocommerce-MyAccount-navigation ul,
ul.woocommerce-error,
ul.matching-items,
ul.post-categories,
ul.tabs,
ul.secondary-menu,
ul.main-menu,
ul.footer-menu,
ul.sub-menu,
ul.g_fields,
ul.slick-dots,
ul.social-menu,
ul.flex,
ul.slick{
	list-style: none;
	padding-left: 0;
	padding-top: 0;

	> li{
		padding-left: 0;
		margin-bottom: 0;

		&:before,
		&:after{
			display: none;
		}
	}
}

ol:not(.pagination){
	list-style-position: outside;
	padding-left: 0px;
	counter-reset: section;

	li{
		padding-left: 23px;
		list-style: none;
		position: relative;

		&:before{
			counter-increment: section;
			content: counter(section)".";
			position: absolute;
			left: 0;
		}
	}
}

table{
	width: 100%;
	background-color: transparent;
}

table,
table tr,
table th,
table td{
	border: none;
	border-collapse: collapse;
	padding: 5px 5px;
	@include font-size(29);
	@include line-height(29,20);
	line-height: 1.2;
	vertical-align: middle;
	text-align: left;
	font-weight: 700;
	color: $colour;

	@media (max-width: $b2){
		@include font-size(17);
		@include line-height(17,20.4);
	}

	tr{
		height: auto!important;
		padding: 0;
	}

	th{
		padding: 17px 10px;
		font-weight: 700;
		font-family: $alt-font;
	}

	th,
	td{
		width: auto!important;
		height: auto!important;

		&:last-of-type{
			border-right: none;
		}
	}
}

html#BTT body table{
	margin-bottom: 1em;
	border: none;
	padding: 10px 0;

	@media (max-width: $b1){
		margin-bottom: 1.7em;
	}

	thead tr{
		border-bottom: 1px solid #707070;
	}
}

.wp-caption{

	.wp-caption-text{
		@include font-size(15);
		font-weight: 700;
		color: $colour;

		@media (max-width: $b3){
			padding-left: 22px;
		}

		&:last-of-type:last-of-type{
			margin-bottom: 0;
			padding-top: 14px;
		}
	}
}

svg,
p.p-img a,
.wp-caption,
video,
img{
	max-width: 100%;
	width: auto;
	height: auto;
	display: block;

	&.alignnone{
		margin: 2.95em 0 1.95em;
	}

	&.alignright {
		float:right;
		margin: 2.95em 0 1.95em 1.95em;
	}

	&.alignleft {
		float:left;
		margin: 2.95em 1.95em 1.95em 0;
	}

	&.aligncenter {
		clear: both;
		display: block;
		margin: 2.95em auto 1.95em;
	}
}

a img.alignright {
	float:right;
	margin: 2.95em 0 1.95em 1.95em;
}

a img.alignleft {
	float:left;
	margin: 2.95em 1.95em 1.95em 0;
}

a img.aligncenter {
	clear: both;
	display: block;
	margin: 2.95em auto 1.95em;
}

::selection {
	background: $blue;
	color: white;
}

::-moz-selection {
	background: $blue;
	color: white;
}

.fieldset{
	margin-bottom: 0;

	.field{
		position: relative;
		padding-bottom: 1px;
	}

	div.mage-error[generated]{
		position: absolute;
		bottom: 5px;
	}
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
input:focus-visible,
textarea:focus-visible,
select:focus-visible{
	border-color: $grey!important;
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
input.mage-error:focus-visible,
select.mage-error:focus-visible,
textarea.mage-error:focus-visible,
.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid,
li.gfield.error input,
.gfield_error input,
.gfield_error select,
.gfield_error .dk-select,
.gfield_error textarea,
input.mage-error,
select.mage-error,
textarea.mage-error{
	border-color: $red!important;
}

div.mage-error[generated] {
	color: $red;
	position: absolute;
	margin-top: 4px;
	line-height: 1.1em;
	padding-top: 2px;
	padding-left: 14px;
	@include svg_img("../images/error-arrow");
	background-position: 0 0;
	margin-left: -2px;
	text-align: left;
}

.browsehappy{
	position: fixed;
	top: 0;
	left: 50%;
	z-index: 999999999;
	background-color: white;
	margin-bottom: 0;
	width: 540px;
	margin-left: -270px;
	text-align: center;
	padding: 4px 2px;
	font-size: 13px;
	color: black;
	border-radius: 0 0 4px 4px;
	line-height: 13px;
	font-weight: 400;

	span{
		background-image: url(../images/browserhappy-cross.png);
		background-repeat: no-repeat;
		height: 13px;
		width: 13px;
		@include inline;
		vertical-align: middle;
		background-position: 50% 50%;

		&:hover{
			background-color: #f96059;
			transition: $time;
		}
	}

	strong{
		font-weight: 700;
	}

	a{
		color: $red;
		text-decoration: none;
		font-weight: 400;

		&:hover{
			color: $red;
			text-decoration: underline;
		}
	}
}

.ta{
	display: table;
	width: 100%;
	height: 1px;
}

.tc{
	display: table-cell;
	vertical-align: middle;
}

.waypoint{
	transition: .4s;
}

.flex{
	display: flex;
	flex-wrap: wrap;

	&.center{
		align-items: center;
		justify-content: center;
	}

	&.top{
		align-self: flex-start;
	}

	&.bottom{
		align-self: flex-end;
	}

	&.no-wrap{
		flex-wrap: nowrap;
	}
}

pre{
	@include font-size(10);
}
