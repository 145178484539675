@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/Montserrat-Regular.woff') format('woff'),
        url('../fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Medium.woff2') format('woff2'),
        url('../fonts/Montserrat-Medium.woff') format('woff'),
        url('../fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('../fonts/Montserrat-SemiBold.woff') format('woff'),
        url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/Montserrat-Bold.woff') format('woff'),
        url('../fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Book.woff2') format('woff2'),
        url('../fonts/Gotham-Book.woff') format('woff'),
        url('../fonts/Gotham-Book.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/Gotham-Bold.woff') format('woff'),
        url('../fonts/Gotham-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'BlackJack';
    src: url('../fonts/BlackJackRegular.woff2') format('woff2'),
        url('../fonts/BlackJackRegular.woff') format('woff'),
        url('../fonts/BlackJackRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

//BREAK POINTS
$b1:				1722px - 1px; //$base-width + $grid-gutter-width - 1px
$b2:				992px - 1px; //992px - 1px
$b3:				769px - 1px; //769px - 1px
$b4:				576px - 1px; //576px - 1px
$b5:				480px - 1px; //450px

//xs: 0,
//sm: 576px,
//md: 768px,
//lg: 992px,
//xl: $base-width

//!!COLOURS

$red:				#c30007;

$green:				#1ea400;

$blue-light:		#dfe7e9;
$blue:				#8fb0b7;

$purple:			#88657a;

$pink:				#cea2a5;

$grey-light:		#ececec;
$grey:				#e5e5e5;

$white:				#ffffff;
$black:				#000000;

$colour: 			$purple;
$main-bg-colour:	$white;

$font-mont:			'Montserrat', serif;;
//regular - 400
//medium - 500
//semi-bold - 600
//bold - 700

$font-goth:			'Gotham', serif;;
//book - 500
//bold - 700

$font-black:		'BlackJack', serif;;
//regular - 400

$main-font:			$font-mont;
$alt-font:			$font-goth;
$alt-font2:			$font-black;

//!!GRID SETTINGS
$grid-columns:		12 !default;
$grid-gutter-width:	41px !default;

//!!PAGE WIDTH
$base-width:		1722px;

//!!SITE TRANSITIONS
$time:				.25s;