  //!!FORM
::-webkit-input-placeholder {
	@include font-size(22);
	color: $purple;

	@media (max-width: $b2){
		@include font-size(17);
	}
}

:-moz-placeholder { /* Firefox 18- */
	@include font-size(22);
	color: $purple;

	@media (max-width: $b2){
		@include font-size(17);
	}
}

::-moz-placeholder {  /* Firefox 19+ */
	@include font-size(22);
	color: $purple;

	@media (max-width: $b2){
		@include font-size(17);
	}
}

:-ms-input-placeholder {
	@include font-size(22);
	color: $purple;

	@media (max-width: $b2){
		@include font-size(17);
	}
}

form{
	margin: 0 auto;
	width: auto;
	display: block;
	margin-bottom: 0;

	&:after{
		visibility:hidden;
		display:block;
		font-size:0;
		content:" ";
		clear:both;
		height:0;
	}

	* html &{
		height:1%;
	}
}

$input-bottom-margin: 48px;
$label-bottom-margin: 18px;

input,
input.input-text,
textarea,
select{
	display: block;
	padding: 5px 10px;
	@include font-size(22);
	font-weight: 400;
	color: $purple;
	font-family: $main-font;
	width: 100%;
	max-width: 100%;
	margin-bottom: $input-bottom-margin;
	border: 2px solid $grey-light;
	background-color: $white;
	border-radius: 0;
	line-height: 1em;
	line-height: 1.2932em;
	-webkit-appearance: none;
	box-shadow: none;

	@media (max-width: $b2){
		padding: 6.25px 21px;
		@include font-size(17);
		margin-bottom: 22px;
	}
}

textarea{
	height: 216.5px;
	min-height: 216.5px;
	min-width: 100%;

	@media (max-width: $b3){
		height: 221.5px;
		min-height: 221.5px;
	}
}

input:focus-visible,
input.input-text:focus-visible,
textarea:focus-visible,
select:focus-visible{
	outline: none;
}

input:focus-visible,
input.input-text:focus-visible,
textarea:focus-visible,
select:focus-visible{
	outline: none;
	transition: color 0.5s ease, background-color 0.5s ease;
}

[type="file"]{
	border: none;
	padding: 0;
}

//
//!!SELECT START
//
select{
	padding-right: 10px;
	appearance: none;
	@include svg_img("../images/select-icon");

	background-position: 100% 50%;
	line-height: 43px;
	height: 43px;
	overflow: hidden;
	padding-top: 0;
	padding-bottom: 0;

	&::-ms-expand {
		display: none;
	}

	option{
		padding-top: 5px;
		padding-bottom: 5px;
		color: $colour;
	}
}
//
//!!SELECT END
//

label{
	display: block;
	@include font-size(22);
	font-family: $main-font;
	margin-bottom: $label-bottom-margin;
	transition: $time;
	font-weight: 700;
	color: $purple;

	@media (max-width: $b3){
		@include font-size(17.7602);
		text-align: center;
		margin-bottom: 11px;
	}

	.gfield_required{
		display: none;
	}

	+ .small{
		margin-top: -21px;
		margin-bottom: 1.655em;
	}
}

*[type="submit"],
.submit-con [type="submit"]{
	@extend %btn;
	margin-bottom: 0;
	width: auto;
	cursor: pointer;
	padding: 7px 9px;
    @include font-size(22);
	min-width: 210px;
	border-radius: 8px;
	border-color: $purple;
	background-color: $purple;

	&:hover,
	&:focus-visible{
		background-color: transparent;
		border-color: $purple;
		color: $purple!important;
	}
}

.submit-con{
	display: flex;
	justify-content: center;
}

//
//!!RADIO & CHECKBOX START
//

.input-radio,
.input-checkbox{
	position: relative;
	@include inline;
	margin-bottom: 5px;

	&:before{
		content: '';
		display: block;
		position: absolute;
	}
}

input[type="radio"],
input[type="checkbox"]{
	display: none;

	& + span,
	& + .wpcf7-list-item-label,
	& + label{
		padding-left: 45px!important;
		position: relative;
		cursor: pointer;
		@include font-size(17);
		line-height: 33px;
		@include inline;
		margin-bottom: 5px;
		font-style: normal;
		color: $colour;
		font-weight: 400;
		text-transform: none;
		text-align: left!important;

		a{
			color: $colour;
			font-weight: 700;
			text-decoration: underline;

			&:hover,
			&:focus-visible{
				color: $pink;
			}
		}

		&:before{
			position: absolute;
			content: "";
			width: 33px;
			height: 33px;
			left: 0;
			top: 1px;
			border-radius: 0px;
			background-position: 0% 50%;
			border: 1px solid $purple;
			background-color: $white;
			font-weight: 400;
		}

		&:after{
			position: absolute;
			content: "";
			width: 19px;
			height: 19px;
			line-height: 29px;
			text-align: center;
			font-weight: 500;
			left: 7px;
			top: 8px;
			background-color: $purple;
			opacity: 0;
			transition: $time;
			border-radius: 0;
			background-size: 100% auto;
		}
	}

	&:checked + label:after,
	&:checked + .wpcf7-list-item-label:after,
	&:checked + span:after,
	& + label.checked:after{
		opacity: 1;
	}
}

input[type="radio"]{

	& + span,
	& + .wpcf7-list-item-label,
	& + label{

		&:before,
		&:after{
			border-radius: 33px;
		}
	}
}

//
//!!RADIO & CHECKBOX END
//


.gform_wrapper{

	.gform_submission_error{
		text-align: center;
		@include font-size(29);
		color: $red;
	}

	.gfield{
		position: relative;

		.validation_message{
			@include font-size(16);
			position: absolute;
			bottom: -19px;

			&.gfield_validation_message{
				color: $red;
			}
		}
	}
}

.gform_confirmation_wrapper{

	.gform_confirmation_message{
		color: $purple;
	}
}
