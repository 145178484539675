//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'grid';/* adapted boostap 4 grid*/
@import 'header';
@import 'footer';
@import 'buttons';
@import 'forms';
@import 'slick';
@import 'slick-theme';
@import 'fancybox';
//@import 'tabs';

//
//!!SITE WIDE STYLE
//

//
//!!STRUCTURE START
//

@media (min-width: $b3 + 1){

	//html{
	//	zoom: 67%;//added as a design size fix, may need to come back to this if there are issues
	//}
}

.matchHeight{
	margin-bottom: 0;
}

.lazy-loader{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: white;
	z-index: 9999;

	&.loaded{
		opacity: 0;
		display: none;
	}
}

.main-wrapper{
	padding-top: 0;
	padding-bottom: 0px;
	width: 100%;
}

.page-wrapper{
	margin: 0 auto;
	padding: 0;
	width: 100%;
	position: relative;
	transition: .25s;
	height: auto;
	padding-top: 0;
	bottom: 0;
}

//
//!!STRUCTURE END
//

//
//!!SIMPLE BASE STYLES START
//

.full-bg{
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

//
//!!SIMPLE BASE STYLES END
//

//
//!! BREADCRUM START
//

.breadcrumb-con{
	@include font-size(18);
	line-height: 1.2em;
	margin-bottom: 50px;
	color: $colour;
	font-weight: 400;
	display: flex;
	justify-content: flex-start;

	@media (max-width: $b3){
		margin-bottom: 30px;
	}

	span{
		color: white;
		@include font-size(18);
		line-height: 1.2em;
		display: inline-block;

		@media (max-width: $b3){
			@include font-size(12);
		}
	}

	a{
		@include font-size(18);
		letter-spacing: .015em;
		line-height: 1.2em;
		text-decoration: none;
		@include inline;
		vertical-align: middle;
		color: $red;
		font-weight: 400;
		margin-right: .2em;
		position: relative;
		display: inline-block;

		@media (max-width: $b3){
			@include font-size(12);
		}

		&:after{
			content: '/';
			display: inline-block;
			margin-left: .4em;
			color: $colour;
		}


		&:hover,
		&:focus-visible{
			color: $red;
			text-decoration: none;
		}
	}

	> a{
		margin-left: 0;
	}

	.breadcrumb_last{
		@include font-size(18);
		letter-spacing: .015em;
		line-height: 1.2em;
		color: $colour;
		text-decoration: none;
		@include inline;
		vertical-align: middle;
		font-weight: 400;
		position: relative;

		@media (max-width: $b3){
			@include font-size(12);
		}
	}
}

//
//!! BREADCRUM END
//

//.pagination-simple{
//	padding: 7px 0 2px;
//
//	a{
//		color: $red;
//		font-weight: 700;
//		padding-top: 1px;
//		@include inline;
//		@include font-size(20);
//		@include line-height(20,33);
//		letter-spacing: .03em;
//		margin-bottom: 1em;
//		text-transform: uppercase;
//
//		@media (max-width: $b2){
//			@include font-size(20 / $divider);
//		}
//
//		&:hover,
//		&:focus-visible{
//			color: $red;
//		}
//
//		&.back-to,
//		&[rel="next"]{
//			float: left;
//		}
//
//		&[rel="prev"]{
//			float: right;
//		}
//	}
//}

//simple pagination

//complex pagination

//.pagination-con{
//	display: flex;
//	justify-content: space-between;
//
//	@media (max-width: $b2){
//		justify-content: center;
//		flex-direction: column;
//		align-items: center;
//
//		.pagination.pagination.pagination{
//			margin-bottom: 20px;
//		}
//	}
//}
//
//.pagination-under{
//
//	.col-12{
//		display: flex;
//		justify-content: center;
//		align-items: center;
//
//		.pagination.pagination{
//			margin-bottom: 50px;
//		}
//	}
//}
//

.pagination-con{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.pagination.pagination.pagination{
	margin-bottom: 0;
	vertical-align: middle;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	background-color: $purple;

	.pagination-mid{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	div{
		text-align: center;
		margin: 0 8.5px;
		@include font-size(21.5082);
		line-height: 31.9239px;
		position: relative;
		z-index: 1;


		&:not(.prev):not(.next){

			&.active{
				pointer-events: none;

				a{
					font-weight: 700;
					line-height: 29.9239px;
					padding-bottom: 2px;
				}
			}

			a{
				@include font-size(21.5082);
				line-height: 31.9239px;
				display: block;
				color: $white;
				font-weight: 400;
				padding: 0;

				&:hover,
				&:focus-visible{
					text-decoration: none;
					color: $blue;
				}
			}

			&.ellipse{
				color: $white;
			}
		}

		&.next,
		&.prev{

			a{
				@include font-size(0);
				line-height: 31.9239px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $white;
				text-decoration: none;
				background-position: 50% 37%;
				background-repeat: no-repeat;
				width: 8.666px;
				height: 31.9239px;

				&:hover,
				&:focus-visible{
					text-decoration: none;
				}
			}
		}

		&.next{

			a{
				background-image: url(../images/arrow-right-white.svg);
				background-position: 52% 50%;

				&:hover,
				&:focus-visible{
					background-image: url(../images/arrow-right-blue.svg);
				}
			}
		}

		&.prev{

			a{
				background-image: url(../images/arrow-left-white.svg);
				background-position: 48% 50%;

				&:hover,
				&:focus-visible{
					background-image: url(../images/arrow-left-blue.svg);
				}
			}
		}
	}
}

//
//!!SOCIAL MENU START
//
$social-size:		34;
$social-addition:	18;

$facebook:		#3b5999;
$twitter:		#55acee;
$linkedin:		#007bb5;
$pinterest:		#cb2027;
$rss:			#ff6600;
$slideshare:	#f7941e;
$google-plus:	#dd4b39;
$instagram:		#8a3ab9;
$reddit:		#ff4500;
$youtube:		#bb0000;
$mail:			#767676;
$behance:		#131418;

.social-menu{
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	a{
		@include font-size($social-size);
		line-height: $social-size + $social-addition + px;
		height:  $social-size + $social-addition + px;
		min-width: $social-size + $social-addition + px;
		border-radius: $social-size + $social-addition + px;
		display: block;
		text-align: center;
		color: $purple;
		text-decoration: none;
		background-color: $blue;
		padding: 0;
		font-weight: 100;
		margin: 0 17px;

		@media (max-width: 400px){
			margin: 0 10px;
		}

		&:first-of-type{
			margin-left: 0;
		}

		&:last-of-type{
			margin-right: 0;
		}


		i{
			@include font-size($social-size);
			line-height: $social-size + $social-addition + px;
			min-width: $social-size + $social-addition + px;
			display: block;
			transition: $time;
		}

		&.facebook{

			&:hover,
			&:focus-visible{

				i{
					color: $facebook!important;
				}
			}
		}
//
//
//		&.behance{
//
//			&:hover,
//			&:focus-visible{
//				color: $behance!important;
//			}
//		}
//
		&.twitter{

			&:hover,
			&:focus-visible{

				i{
					color: $twitter!important;
				}
			}
		}

		&.linkedin{

			&:hover,
			&:focus-visible{

				i{
					color: $linkedin!important;
				}
			}
		}

		&.pinterest{

			&:hover,
			&:focus-visible{

				i{
					color: $pinterest!important;
				}
			}
		}
//
//		&.rss{
//
//			&:hover,
//			&:focus-visible{
//				color: $rss!important;
//			}
//		}
//
//		&.slideshare{
//
//			&:hover,
//			&:focus-visible{
//				color: $slideshare!important;
//			}
//		}
//
//		&.google-plus{
//
//			&:hover,
//			&:focus-visible{
//				color: $google-plus!important;
//			}
//		}
//
		&.instagram{

			&:hover,
			&:focus-visible{

				i{
					color: $instagram!important;
				}
			}
		}
//
//		&.reddit{
//
//			&:hover,
//			&:focus-visible{
//				color: $reddit!important;
//			}
//		}
//
//		&.youtube{
//
//			&:hover,
//			&:focus-visible{
//				color: $youtube!important;
//			}
//		}
//
//		&.mail{
//
//			&:hover,
//			&:focus-visible{
//				color: $mail!important;
//			}
//		}
	}
}

//
//!!SOCIAL MENU END
//

.waypoint{
	transition: .5s;
	opacity: 0;
	visibility: hidden;
	transform: translateY(50px);
	will-change: transform;

	body.ie &{
		opacity: 1;
		visibility: visible;
		transition: 0s;
	}

	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}

.hero-slide-section{
	background-color: $purple;

	&.single-slide{

		.hero-slide{

			hr{
				margin: 2.5em 0 1.54em;

				@media (max-width: $b3){
					margin: 1.7em auto 0.94em;
				}
			}

			.text{

				@media (min-width: $b3 + 1){
					flex: 0 0 57%;
					max-width: 57%;
					padding-bottom: 0px;
					padding-top: 100px;
				}
			}

			.image{

				@media (min-width: $b3 + 1){
					flex: 0 0 43%;
					max-width: 43%;
				}

				@media (max-width: $b3){
					margin-top: 12px;
					margin-bottom: 22px;
				}
			}
		}
	}

	.circle-message{
		position: absolute;
		top: 86px;
		right: 18px;
		color: white;
		@include font-size(24);
		line-height: 1.28;
		text-align: center;
		width: 182px;
		height: 182px;
		border-radius: 182px;
		background-color: rgba(white, .5);
		font-weight: 700;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px 10px;
		z-index: 1;
		flex-direction: column;

		small{
			font-size: 50%;
		}

		@media (max-width: $b3){
			display: none;
		}

		&.mob{
			width: 129.8752px;
			height: 129.8752px;
			background-color: $pink;
			@include font-size(17.1713);
			top: -30px;
			right: -12px;
			display: flex;
			transform: rotate(-5deg);
			z-index: 2;

			@media (min-width: $b3 + 1){
				display: none;
			}
		}
	}

	.hero-slide{
		max-height: 704px;

		@media (max-width: $b3){
			max-height: none;
		}

		.container{
			max-width: 1501px;
			background-image: url(../images/hero-leaf.png);
			background-position: 100% 100%;
			background-repeat: no-repeat;
			position: relative;

			@media (max-width: $b3){
				background-image: none;
			}

			*{
				color: white;
			}
		}

		hr{
			margin-left: 0;
			margin-right: 0;

			@media (max-width: $b3){
				margin-left: auto;
				margin-right: auto;
			}
		}

		.row{
			height: 704px;
			padding-top: 50px;
			padding-bottom: 50px;
			align-items: center;

			@media (max-width: $b3){
				padding-top: 25px;
				text-align: center;
				height: 750px;
				height: auto;
				min-height: 750px;
				align-items: flex-start;
			}
		}

		.text{
			padding-bottom: 1.3em;

			@media (min-width: $b3 + 1){
				padding-bottom: 20px;
			}

			h1{

				@media (min-width: $b2 + 1){
					padding-right: 50px;
				}
			}

			a{

				&:hover,
				&:focus-visible{
					background-color: transparent;
					border-color: white;
					color: white!important;
				}
			}

			h2{
				@include font-size(65.6026);
				font-family: $alt-font2;
				font-weight: 400;

				@media (max-width: 1700px){
					@include font-size(43);
				}

				@media (max-width: $b2){
					@include font-size(43);
				}

				@media (max-width: 400px){
					@include font-size(30);
				}
			}
		}

		.image{
			display: flex;
			justify-content: center;
			align-items: center;

			@media (min-width: $b2 + 1){
				padding-right: 180px;
			}

			.img-con{
				transform: rotate(5deg);
				border-radius: 16px;
				overflow: hidden;
				position: relative;
				display: inline-block;

				@media (max-width: $b3){
					width: 87%;
					max-width: 365px;
					margin-top: 16px;
					overflow: visible;

					img{
						border-radius: 16px;
					}
				}

				&:after{
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					border-radius: 10px;
					border: 2px solid $pink;
					height: calc(100% - 30px);
					width: calc(100% - 30px);

					@media (max-width: $b3){
						height: calc(100% - 22px);
						width: calc(100% - 22px);
					}
				}
			}
		}

		.btn{
			margin-top: .6em;

			@media (max-width: $b3){
				margin-top: 0;
			}
		}
	}
}

.hero-section{
	background-color: $purple;

	.page-id-341 &{

		@media (min-width: $b2 + 1){

			.text{
				padding-top: 104px;
			}

			h1{
				width: 115%;
				max-width: 115%;
			}

			h2{
				width: 107%;
				max-width: 107%;
			}
		}
	}

	.container{
		max-width: 1501px;

		*{
			color: white;
		}
	}

	hr{
		margin-left: 0;
		margin-right: 0;

		@media (max-width: $b3){
			margin-left: auto;
			margin-right: auto;
		}
	}

	.row{
		height: 704px;
		padding-top: 50px;
		padding-bottom: 50px;
		align-items: center;

		@media (max-width: $b3){
			height: auto;
			min-height: 826px;
			padding-top: 25px;
			text-align: center;
			align-items: flex-start;
		}
	}

	.text{
		padding-top: 1.4em;

		@media (min-width: $b3 + 1){
			flex: 0 0 58%;
			max-width: 58%;
		}

		@media (min-width: 1200px + 1){
			flex: 0 0 64%;
			max-width: 64%;
		}

		@media (max-width: $b3){
			padding-top: 0;
		}

		@media (min-width: $b2 + 1){
			padding-right: 60px;
		}

		h1{
			margin-bottom: 1.05em;

			@media (max-width: $b3){
				margin-bottom: .75em;
			}
		}

		hr{

			+ h2{
				margin-top: 1em;

				@media (max-width: $b3){
					margin-top: -.3em;
				}
			}
		}

		h2{
			@include font-size(65.6026);
			font-family: $alt-font2;
			font-weight: 400;

			@media (max-width: 1700px){
				@include font-size(43);
			}

			@media (max-width: $b2){
				@include font-size(43);
			}

			@media (max-width: 400px){
				@include font-size(30);
			}
		}
	}

	.image{
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		align-self: stretch;

		@media (min-width: $b3 + 1){
			flex: 0 0 42%;
			max-width: 42%;
		}

		@media (min-width: 1200px + 1){
			flex: 0 0 36%;
			max-width: 36%;
		}

		@media (min-width: $b2 + 1){
			padding-right: 180px;
		}

		.img-con{
			border-radius: 16px;
			overflow: hidden;
			position: absolute;
			display: inline-block;
			width: 288px;
			max-width: 288px;
			height: 344px;

			@media (max-width: 1700px){
				border-radius: 10px;
				width: 172px;
				max-width: 172px;
				height: 205px;
			}

			&:nth-of-type(1){
				top: 28.8%;
				left: 48.6%;
				transform: translate(-50%, -50%) rotate(-22.1deg);

				@media (max-width: 1700px){
					top: 39.8%;
				}

				@media (max-width: $b3){
					top: -28.2%;
					left: 45.6%;
				}
			}

			&:nth-of-type(2){
				top: 69.8%;
				left: 28.4%;
				transform: translate(-50%, -50%) rotate(14.8deg);

				@media (max-width: $b3){
					top: 41.8%;
					left: 29.4%;
				}
			}

			&:nth-of-type(3){
				top: 67.9%;
				left: 81.4%;
				transform: translate(-50%, -50%) rotate(-15.1deg);

				@media (max-width: 1700px){
					left: 71.4%;
				}

				@media (max-width: $b3){
					top: 37.9%;
					left: 70.4%;
				}
			}

			&:after{
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border-radius: 12px;
				border: 2px solid $pink;
				height: calc(100% - 15px);
				width: calc(100% - 15px);

				@media (max-width: $b3){
					border-radius: 6px;
				}
			}
		}
	}
}

.text-hero-section{
	border-top: 25px solid $purple;
	padding-top: 95px;

	@media (max-width: $b3){
		border-top: none;
		padding-top: 45px;
	}

	.container{
		max-width: 1600px;
	}

	h1{
		letter-spacing: .01em;

		@media (min-width: $b2 + 1){
			@include font-size(75);
		}
	}
}

.wrap-decoration{
	position: relative;
	background-image: url(../images/wd0.jpg), url(../images/wd2.jpg);
	background-position: 0% 0%, 100% 77%;
	background-repeat: no-repeat;

	@media (max-width: $b3){
		background-size: auto 150px, auto 150px;
		background-position: 0% 0px, 100% 0px;
	}
}

.image-section{
	padding-top: 140px;
	padding-bottom: 70px;

	@media (max-width: $b3){
		padding-top: 65px;
		padding-bottom: 35px;
	}


	.container{
		max-width: 1279px;
	}

	img{
		width: 100%;
		margin: 0 auto;
		border: 5px solid $pink;
		border-radius: 20px;

		@media (max-width: $b3){
			border-radius: 8px;
			border: 2px solid $pink;
		}
	}

	video{
		width: 100%;
		margin: 0 auto;
		border: 5px solid $pink;
		border-radius: 20px;
		background-color: $pink;

		@media (max-width: $b3){
			border-radius: 8px;
			border: 2px solid $pink;
		}
	}
}

.center-hero-section + .our-season-favourites-section{
	padding-top: 117px;
}

.our-season-favourites-section{
	padding-top: 45px;
	padding-bottom: 45px;

	@media (max-width: $b3){
		padding-top: 19px;
		padding-bottom: 19px;
	}

	.title{
		text-align: center;
		margin-bottom: 1.7em;

		@media (max-width: $b3){
			margin-bottom: .9em;
		}

		h2{
			color: $blue;
		}
	}

	.related-products{
		display: flex;
		justify-content: center;
		margin: 0 -21px;
		width: calc(100% + 21px + 21px);
		max-width: calc(100% + 21px + 21px);
		flex-wrap: wrap;

		@media (max-width: $b3){
			margin: 0 -16px;
			width: calc(100% + 16px + 16px);
			max-width: calc(100% + 16px + 16px);
		}


		.p-item{
			margin: 0 21px 42px;
			width: calc(20% - 21px - 21px);

			@media (max-width: $b3){
				margin: 0 16px 24px;
				width: calc(50% - 16px - 16px);
			}
		}
	}

	.btn-con{
		margin-top: 1.8em;

		@media (max-width: $b3){
			margin-top: 1em;
		}
	}
}

.three-blocks-section{
	padding-top: 47px;
	padding-bottom: 80px;

	@media (max-width: $b3){
		padding-top: 27px;
		padding-bottom: 0px;
	}

	// .container{
	// 	max-width: 100%;
	// 	padding-right: 8px;
	// 	padding-left: 8px;

	// 	@media (max-width: $b3){
	// 		padding-right: 16px;
	// 		padding-left: 16px;
	// 	}
	// }

	.row{
		margin-right: -16px;
		margin-left: -16px;
		justify-content: center;
	}

	.col-md-4{
		padding-right: 8px;
		padding-left: 8px;

		@media (max-width: $b3){
			padding-right: 16px;
			padding-left: 16px;
			margin-bottom: 82px;
		}

		.inner{
			width: 100%;
			position: relative;

			.img-con{
				width: 100%;
				height: 728px;

				@media (max-width: $b1){
					height: 630px;
				}

				@media (max-width: $b2){
					height: 550px;
				}

				@media (max-width: $b3){
					height: 436px;
				}
			}

			.bottom{
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				padding: 27px 10px 59px;
				text-align: center;
				background-color: rgba($purple, .85);

				@media (max-width: $b3){
					padding: 17px 10px 36px;
				}

				h4{
					color: white;
					@include font-size(43);

					@media (max-width: 1500px){
						@include font-size(28.7975);
					}

					@media (max-width: $b2){
						@include font-size(25.7975);
					}
				}
			}

			.btn{
				position: absolute;
				bottom: 0;
				width: 226px;
				line-height: 1.1em;
				padding: 11px 16px;
				left: 50%;
				transform: translate(-50%, 60%);
				font-size: 31.785px !important;
				font-size: 3.1785rem !important;

				@media (max-width: $b2){
					width: 135px;
					padding: 6px 13px;
					border-radius: 5px;
					font-size: 19.0692px !important;
					font-size: 1.90692rem !important;

				}

			}
		}
	}
}

.add-joy-section{
	padding-top: 260px;
	padding-bottom: 119px;
	background-image: url(../images/joy-back.jpg);
	background-repeat: no-repeat;
	background-position: 50% 50%;

	@media (max-width: $b3){
		padding-top: 37px;
		padding-bottom: 57px;
		background-image: url(../images/joy-back.jpg), url(../images/joy-back.jpg);
		background-position: 50% 1%, 50% 121%;
		background-size: 170% auto;
	}

	@media (min-width: 1976px + 1){
		background-size: 114% 1324px;
	}

	.row{
		align-items: center;
	}

	.container{
		max-width: 1519px;
	}

	.col-md-7{
		padding-bottom: 140px;

		@media (min-width: $b3 + 1){
			flex: 0 0 55.3%;
			max-width: 55.3%;
		}

		@media (max-width: $b3){
			padding-bottom: 38px;
		}
	}

	.col-md-5{

		@media (min-width: $b3 + 1){
			flex: 0 0 44.7%;
			max-width: 44.7%;
		}

		.btn{
			color: $blue!important;

			&:hover,
			&:focus-visible{
				background-color: transparent;
				border-color: $blue;
				color: $blue!important;
			}
		}
	}

	h3{
		@include font-size(67.7945);

		@media (max-width: $b2){
			@include font-size(30);
			text-align: center;
			margin-bottom: .64em;
		}
	}

	.gfield_label{
		display: none;
	}

	.gform_wrapper{
		width: 669px;

		@media (max-width: $b3){
			width: 100%;
		}
	}

	input:not([type="submit"]),
	input.input-text,
	textarea{
		border: 4px solid $blue;
		border-radius: 15px;
		font-weight: 700;
		@include font-size(22);
		padding: 11.25px 31px 15.25px;
		margin-bottom: 19px;
		background-color: transparent;
		color: $pink;

		@media (max-width: $b2){
			@include font-size(25);
		}

		@media (max-width: $b3){
			border: 3px solid $blue;
			border-radius: 10px;
			padding: 15.25px 31px 16.25px;
			margin-bottom: 10px;
		}
	}

	.small-text{
		position: absolute;
		bottom: 23px;
		right: 0;
		width: calc(100% - 240px);
		@include font-size(21.4052);
		color: $blue;

		@media (max-width: $b3){
			width: 100%;
			position: relative;
			bottom: 0;
			text-align: center;
			margin-bottom: 10px;
		}
	}

	.gform_wrapper{
		position: relative;
	}

	.gform_footer{

		@media (max-width: $b3){
			text-align: center;
		}
	}

	@media (max-width: $b3){

		.image-side{
			padding-right: 38px;
			padding-left: 38px;
		}
	}

	.gallery-side-slide{

		@media (max-width: $b3){
			margin: 0 auto;
			width: 338px;
			max-width: 100%;
		}

		.full-bg{
			height: 864px;
			border-radius: 864px 864px 0 0;

			@media (max-width: $b2){
				height: 457px;
			}

			&:after{
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border-radius: 864px 864px 0 0;
				border: 3px solid $purple;
				height: calc(100% - 50px);
				width: calc(100% - 50px);

				@media (max-width: $b3){
					border: 2px solid $purple;
					height: calc(100% - 25px);
					width: calc(100% - 25px);
				}
			}
		}
	}

	.btn-con{
		margin-top: 41px;

		@media (max-width: $b3){
			margin-top: 26px;
		}
	}
}

.be-part-section{
	background-color: $purple;
	padding-top: 50px;
	padding-bottom: 50px;

	@media (max-width: $b3){
		padding-top: 24px;
		padding-bottom: 25px;
	}

	.row{
		justify-content: center;
	}

	.error404 &{
		display: none;
	}

	*{
		color: white;
	}

	.title{
		text-align: center;
		margin-bottom: 2.11em;

		h3{
			margin-bottom: 1.1em;

			@media (max-width: $b3){
				margin-bottom: .6em;
			}
		}
	}

	.social-menu.social-menu{

		a{
			color: $purple;

			*{
				color: $purple;
			}

			&:hover,
			&:focus-visible{
				color: $purple!important;
				background-color: white;

				*{
					color: $purple!important;
				}
			}
		}
	}

	.s-item{
		height: 291px;
		background-color: red;
		margin-bottom: 41px;

		@media (min-width: $b2 + 1){
			flex: 0 0 20%;
			max-width: 20%;
		}

		@media (max-width: $b3){
			display: none;
		}
	}

	hr{
		margin: 1.34em auto 0;

		@media (max-width: $b3){
			display: none;
		}
	}
}

.bottom-quotes-section{
	background-color: $blue;
	padding-top: 116px;
	padding-bottom: 63px;
	background-image: url(../images/quote-leaf.png);
	background-repeat: no-repeat;
	background-position: 0 100%;

	@media (max-width: $b3){
		padding-top: 29px;
		padding-bottom: 35px;
		text-align: center;
		background-image: none;
	}

	.error404 &{
		display: none;
	}

	.container{
		position: relative;
		z-index: 2;
	}

	.row{
		align-items: center;
	}

	*{
		color: white;
	}

	.quote-slide{
		margin: 0 -20.5px;
		width: calc(100% + 20.5px + 20.5px);
		max-width: calc(100% + 20.5px + 20.5px);

		@media (max-width: $b3){
			margin: 0 -20.5px 20.5px;
		}

		.slick-list{
			padding: 0!important;
		}

		.slick-track{
			display: flex;


		}
	}

	.slick-slide{
		display: flex!important;
		margin-bottom: 41px;
		margin: 0 20.5px;
		height: auto;

		// @media (min-width: $b3 + 1){
		// 	align-self: stretch;
		// }

		// @media (max-width: $b3){
		// 	padding-right: 36px;
		// 	padding-left: 36px;
		// 	margin-bottom: 23px;

		// 	&:nth-of-type(2){
		// 		display: none;
		// 	}
		// }

		.inner{
			border-radius: 20px;
			background-color: rgba(white, .4);
			padding: 121px 40px 63px;
			text-align: center;
			position: relative;
			width: 100%;

			@media (max-width: $b1){
				padding: 121px 25px 63px;
			}

			@media (max-width: $b3){
				border-radius: 10px;
				padding: 78px 25px 39px;
			}

			&:before{
				content: '';
				position: absolute;
				top: 53px;
				left: 50%;
				background-image: url(../images/hearts.svg);
				background-repeat: no-repeat;
				transform: translateX(-50%);
				width: 211px;
				height: 39px;

				@media (max-width: $b3){
					top: 33px;
					width: 134px;
					height: 26px;
					background-size: 100% auto;
				}
			}
		}
	}

	.col-lg-4:not(.col-md-6){
		margin-bottom: 41px;

		@media (max-width: $b3){
			margin-bottom: 0;
		}

		.inner{
			padding: 0 32px 23px;
			position: relative;

			@media (max-width: $b3){
				padding: 0 0px 0px;
			}
		}

		h4{
			line-height: 1.52;

			@media (max-width: $b3){
				text-align: center;
				line-height: 1.2;
			}
		}

		.btn{
			width: auto;
			min-width: 208px;
			position: absolute;
			bottom: -1px;
			right: 32px;

			@media (max-width: 1750px){
				bottom: -35px;
			}


			@media (max-width: $b3){
				position: static;
				margin-top: 2px;
				min-width: 135px;
			}
		}
	}
}

.image-section + .text-with-image-section{
	padding-top: 275px;
	padding-bottom: 196px;
	background-position: 50% 78%;
	background-size: 110% auto;
	background-position: 49.7% -61%;

	@media (max-width: 1976px){
		background-size: 117% auto;
	}

	@media (max-width: $b3){
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.text{

		@media (min-width: $b3 + 1){
			padding-right: 25px;
		}
	}
}

.text-with-image-blue-section,
.text-with-image-purple-section,
.text-with-image-alt-section,
.text-with-image-section{
	padding-top: 212px;
	padding-bottom: 300px;
	background-image: url(../images/joy-back.jpg);
	background-repeat: no-repeat;
	background-position: 50% 117%;

	@media (max-width: $b3){
		padding-top: 116px;
		padding-bottom: 148px;
		text-align: center;
		background-position: 50% 87%;

		p{
			line-height: 1.35em;
		}
	}

	@media (min-width: 1976px + 1){
		background-size: 114% 1324px;
	}

	ul{

		li{
			padding-left: 55px;

			@media (max-width: $b2){
				padding-left: 47px;
				text-align: left;
			}

			&:before{
				background-color: transparent;
				width: 32px;
				height: 35px;
				background-size: 100% auto;
				background-image: url(../images/heart-bullet.svg);
				background-repeat: no-repeat;
				border-radius: 0;
				left: 0.21em;
				top: 0.08em;

				@media (max-width: $b2){
					width: 32px;
					height: 35px;
					background-size: 100% auto;
				}
			}
		}
	}

	.row{
		align-items: center;
	}

	.container{
		max-width: 1602px;
	}

	.text{
		padding-top: 64px;

		@media (max-width: $b3){
			padding-top: 0!important;
		}

		p{
			letter-spacing: -.008em;
		}
	}

	.image{
		text-align: center;

		@media (min-width: $b3 + 1){
			flex: 0 0 42.6%;
			max-width: 42.6%;
		}

		@media (max-width: $b3){
			margin-top: 39px;
		}
	}

	.img-con{
		position: relative;
		display: inline-block;
		border-radius: 864px 864px 0 0;
		overflow: hidden;

		@media (max-width: $b3){
			height: 457px;
			margin: 0 auto;
			width: 338px;
			max-width: 100%;
		}

		&:after{
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 864px 864px 0 0;
			border: 3px solid $purple;
			height: calc(100% - 50px);
			width: calc(100% - 50px);

			@media (max-width: $b3){
				border: 2px solid $purple;
				height: calc(100% - 25px);
				width: calc(100% - 25px);
			}
		}
	}
}

.text-with-image-section{

	@media (max-width: $b3){
		background-image: url(../images/joy-back.jpg), url(../images/joy-back.jpg);
		background-position: 50% 0%, 50% 102%;
		background-size: 280% auto, 130% auto;
		position: relative;

		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 35px;
			background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		}

	}

	.text{

		@media (min-width: $b3 + 1){
			padding-left: 70px;
			padding-right: 55px;
			flex: 0 0 57.4%;
			max-width: 57.4%;
		}
	}

	video{
		width: 100%;
	}

	.gallery-side-slide{

		@media (max-width: $b3){
			margin: 0 auto;
			width: 338px;
			max-width: 100%;
		}

		.full-bg{
			height: 864px;
			border-radius: 864px 864px 0 0;

			@media (max-width: $b2){
				height: 457px;
			}

			&:after{
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border-radius: 864px 864px 0 0;
				border: 3px solid $purple;
				height: calc(100% - 50px);
				width: calc(100% - 50px);

				@media (max-width: $b3){
					border: 2px solid $purple;
					height: calc(100% - 25px);
					width: calc(100% - 25px);
				}
			}
		}
	}
}

.text-with-image-alt-section{
	padding-top: 352px;
	padding-bottom: 352px;
	background-position: 50% 32%;

	.container{
		max-width: 1601px;
	}

	.text{
		padding-top: 15px;

		@media (min-width: $b3 + 1){
			flex: 0 0 63.4%;
			max-width: 63.4%;
		}
	}

	.image{

		@media (min-width: $b3 + 1){
			flex: 0 0 36.6%;
			max-width: 36.6%;
		}
	}

	.img-con{
		position: relative;
		display: inline-block;
		border-radius: 20px;

		&:after{
			border-radius: 10px;
			border-color: $pink;
			height: calc(100% - 36px);
			width: calc(100% - 36px);
		}
	}
}

.text-with-image-blue-section,
.text-with-image-purple-section{
	background-image: none;
	background-color: $purple;
	padding-top: 54px;
	padding-bottom: 48px;

	*{
		color: white;
	}

	.container{
		max-width: 1601px;
	}

	.text{
		padding-top: 15px;

		@media (min-width: $b3 + 1){
			flex: 0 0 58.9%;
			max-width: 58.9%;
		}
	}

	.image{

		@media (min-width: $b3 + 1){
			flex: 0 0 41.1%;
			max-width: 41.1%;
		}
	}

	.img-con{
		position: relative;
		display: inline-block;
		border-radius: 20px;

		&:after{
			border-radius: 10px;
			border-color: $pink;
			height: calc(100% - 36px);
			width: calc(100% - 36px);
		}
	}
}

.text-with-image-blue-section{
	background-color: $blue;

	.container{
		max-width: 1684px;
	}

	.text{
		padding-top: 0;
		padding-bottom: 11px;

		@media (min-width: $b3 + 1){
			flex: 0 0 58.3%;
			max-width: 58.3%;
		}

		@media (max-width: $b3){

			.btn-con{
				justify-content: center;
			}
		}
	}

	.image{

		@media (min-width: $b3 + 1){
			flex: 0 0 41.7%;
			max-width: 41.7%;
		}

		@media (max-width: $b3){
			margin-top: 0;
		}

		.img-con{

			@media (max-width: $b3){
				height: 287px;
				margin-bottom: 1em;
				width: 288px;
			}
		}
	}

	.btn-con{
		margin-top: 1.8em;

		.btn{

			&:hover,
			&:focus-visible{
				background-color: transparent;
				border-color: $white;
				color: $white!important;
			}
		}
	}

	h3{

		@media (min-width: $b2 + 1){
			@include font-size(48.9139);
		}
	}
}

.text-with-images-section{
	padding-top: 54px;
	padding-bottom: 16px;

	@media (max-width: $b3){
		padding-top: 41px;
		padding-bottom: 16px;
	}

	.row{

		@media (max-width: $b3){
			text-align: center;
		}
	}

	.container{
		max-width: 1602px;
	}

	h3{
		@include font-size(50);
		letter-spacing: .008em;
		margin-bottom: .8em;

		@media (max-width: $b2){
			@include font-size(30);
		}
	}

	.text{
		padding-top: 10px;

		@media (min-width: $b3 + 1){
			padding-left: 70px;
			padding-right: 55px;
			flex: 0 0 73.3%;
			max-width: 73.3%;
		}

		@media (max-width: $b3){
			padding-top: 0;
		}
	}

	.image{
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		align-items: flex-start;

		@media (min-width: $b2 + 1){
			padding-left: 51px;
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 26.7%;
			max-width: 26.7%;
		}

		@media (max-width: $b3){
			padding-top: 84px;
		}

		img{
			border: 5px solid $pink;
			border-radius: 20px;
			margin-bottom: 16px;

			@media (max-width: $b3){
				width: 94%;
				margin-right: auto;
				margin-left: auto;
			}


			&:last-of-type{
				margin-bottom: 0
			}
		}
	}
}

.text-with-image-alt-section + .text-wide-section.text-wide-section{
	padding-top: 22px;
	padding-bottom: 96px;
}

.text-with-image-purple-section + .text-wide-section.text-wide-section{
	padding-top: 114px;
	padding-bottom: 82px;
}

.text-wide-section{
	padding-top: 410px;
	padding-bottom: 34px;
	background-image: url(../images/joy-back.jpg);
	background-repeat: no-repeat;
	background-position: 57% 26px;

	@media (max-width: $b3){
		padding-top: 186px;
		padding-bottom: 150px;
		text-align: center;
		background-size: 351% auto;
		background-position: 30% 17px;

		p{
			line-height: 1.35em;
		}

		ul{
			padding-left: 0;

			li{

				&:before{
					position: relative;
					display: inline-block;
					top: -0.12em;
					width: .15em;
					height: .15em;
					left: -0.19em;
				}
			}
		}
	}

	&:first-of-type{
		border-top: 25px solid $purple;
		padding-top: 10px;
		padding-bottom: 10px;

		@media (max-width: $b3){
			padding-top: 43px;
			border-top: none;
		}

		&:last-of-type{
			padding-bottom: 130px;

			@media (max-width: $b3){
				padding-bottom: 102px;
			}
		}

		~ .text-wide-section{
			padding-top: 62px;
			padding-bottom: 100px;

			&:last-of-type{
				padding-bottom: 130px;

				@media (max-width: $b3){
					padding-bottom: 102px;
				}
			}
		}
	}

	&:first-of-type,
	&:first-of-type ~ .text-wide-section{
		background-image: none;

		.container{
			max-width: 1700px;
		}

		.text{

			@media (min-width: $b3 + 1){
				padding-right: 70px;
			}
		}

		h3{
			@include font-size(50);
			margin-bottom: 1em;


			@media (max-width: $b2){
				@include font-size(21);
			}
		}

		h1{
			// page header
			@include font-size(45);
			margin-bottom: 1.07em;


			@media (max-width: $b2){
				@include font-size(32);
				margin-bottom: .9em;
			}
		}

		h5{
			@include font-size(35);

			@media (max-width: $b2){
				@include font-size(21);
			}

			+ p > strong{
				display: inline-block;
			}
		}

		p{
			margin-bottom: 1.17em;

			@media (max-width: $b3){
				line-height: 1.2em;
			}

			+ h5{
				margin-top: 1.5em;
			}
		}
	}

	h3{

		a{

			&:hover,
			&:focus-visible{
				color: $blue;
			}
		}
	}

	.single &{
		padding-top: 105px;
		background-image: none;

		.container{
			max-width: 1601px;
		}

		.text{

			@media (min-width: $b3 + 1){
				padding-left: 20.5px;
				padding-right: 20.5px;
			}
		}
	}

	@media (min-width: 1976px + 1){
		background-size: 100% 1324px;
	}

	.container{
		max-width: 1602px;
	}

	.text{

		@media (min-width: $b3 + 1){
			padding-left: 70px;
			padding-right: 10px;
		}
	}
}

.address-rows-section,
.address-columns-section{
	padding-top: 16px;
	padding-bottom: 52px;

	.container{
		max-width: 1613px;
	}

	.text{
		margin-bottom: .15em;
	}

	@media (min-width: $b3 + 1){

		.container,
		.col-12{
			padding-right: 27px;
			padding-left: 27px;
		}

		.row{
			margin-right: -27px;
			margin-left: -27px;
		}
	}

	h3{
		letter-spacing: .007em;
		margin-bottom: .57em;

		@media (min-width: $b2 + 1){
			@include font-size(50);
		}
	}

	p{
		margin-bottom: 1.17em;

		@media (min-width: $b2 + 1){
			@include font-size(35);
		}
	}

	h5{
		margin-bottom: .66em;

		@media (min-width: $b2 + 1){
			@include font-size(35);
		}
	}

	.block{
		display: flex;
		margin-bottom: 54px;

		@media (max-width: $b3){
			margin-bottom: 25px;
		}

		*{
			color: white;
		}

		.inner{
			background-color: $purple;
			border-radius: 30px;
			padding: 27px 20px 45px;
			text-align: center;
			width: 100%;

			@media (max-width: $b3){
				padding: 25px 15px 25px;
			}

			address{
				margin-bottom: 0;
			}

			a{

				&:hover,
				&:focus-visible{
					color: $pink;
				}
			}
		}
	}
}

.address-rows-section{
	padding-bottom: 99px;

	@media (max-width: $b3){
		padding-bottom: 50px;
	}

	h3{
		margin-bottom: .44em;
	}

	h5{
		margin-bottom: .09em;
	}

	.text{
		margin-bottom: .74em;
	}

	.par-con{
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@media (max-width: $b3){
			justify-content: center;
		}

		.p-img{

			@media (min-width: $b3 + 1){
				width: 300px;
				margin-right: 1.95em;
			}
		}

		p{
			@media (min-width: $b3 + 1){
				width: calc(100% - 320px - 1.95em);
			}
		}
	}

	.block{

		.inner{
			padding: 35px 20px 46px;

			@media (max-width: $b3){
				padding: 25px 15px 25px;
			}

			@media (min-width: $b2 + 1){
				@include font-size(35);
			}

			address,
			span{

				@media (min-width: $b2 + 1){
					@include font-size(35);
				}
			}

			a{

				&:hover,
				&:focus-visible{
					color: $blue;
				}
			}
		}
	}

	.under{
		margin-top: -.1em;
	}
}

.introduction-section{
	padding-top: 105px;
	padding-bottom: 116px;
	text-align: center;

	@media (max-width: $b3){
		padding-top: 50px;
		padding-bottom: 50px;
	}

	+ .image-section{
		padding-top: 0;
		padding-bottom: 0;
	}

	+ .form-section{
		padding-top: 0;
	}

	.btn-con{
		margin-top: 1.5em;

		.btn{
			min-width: 250px;
		}
	}

	.container{
		max-width: 1535px;
	}
}

.form-section{
	padding-top: 105px;
	padding-bottom: 116px;

	@media (max-width: $b3){
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.container{
		max-width: 1535px;
	}

	+ .image-section{
		padding-top: 0;
		padding-bottom: 0;
	}

	// .gform_wrapper{
	// 	margin-top: 4.5em;

	// 	[type="submit"]{
	// 		min-width: 360px;
	// 	}
	// }

	.gform_fields{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.gfield{
		width: 100%;
	}

	.gfield--width-full{
		width: 100%;
	}

	.gfield--width-half{
		width: calc(50% - 38px);

		@media (max-width: $b3){
			width: 100%;
		}
	}

	.gform_fields{

		.ginput_complex{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			> span{
				width: calc(50% - 38px);

				@media (max-width: $b3){
					width: 100%;
				}
			}

			.gf_clear{
				width: 100%;
			}
		}

		input,
		input.input-text,
		textarea,
		select{
			margin-bottom: 34px;
		}

		input[type="radio"],
		input[type="checkbox"]{

			+ label:before,
			+ label:after{
				border-radius: 33px;
			}
		}

		.ginput_container_consent{
			margin-top: -3px;
			margin-bottom: 28px;
		}

		fieldset{
			padding: 0;
			border: none;

			legend{
				display: none
			}

			.validation_message{
				bottom: 15px;
			}
		}
	}
}

.blog-section{
	padding-top: 155px;
	padding-bottom: 74px;

	@media (max-width: $b3){
		padding-top: 61px;
		padding-bottom: 39px;
	}

	.container{
		max-width: 1679px;
		padding-right: 8px;
		padding-left: 8px;

		@media (max-width: $b3){
			padding-right: 34px;
			padding-left: 34px;
		}
	}

	.row{
		margin-right: -16px;
		margin-left: -16px;

		@media (max-width: $b3){
			margin-right: -34px;
			margin-left: -34px;
		}
	}

	.col-12{
		padding-right: 8px;
		padding-left: 8px;

		@media (max-width: $b3){
			padding-right: 34px;
			padding-left: 34px;
		}
	}

	.col-md-4{
		margin-bottom: 113px;

		@media (max-width: $b3){
			margin-bottom: 78px;
		}

		.inner{
			width: 100%;
			position: relative;

			.img-con{
				width: 100%;
				height: 636px;

				@media (max-width: $b1){
					height: 550px;
				}

				@media (max-width: $b2){
					height: 450px;
				}

				@media (max-width: $b3){
					height: 392px;
				}
			}

			.bottom{
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				padding: 22px 10px 53px;
				text-align: center;
				background-color: rgba($purple, .85);

				@media (max-width: $b3){
					padding: 15px 10px 31px;
				}

				h4{
					color: white;
					@include font-size(32);
					text-transform: uppercase;
					line-height: 1.2;

					@media (max-width: $b2){
						@include font-size(23);
					}
				}
			}

			.btn{
				position: absolute;
				bottom: 0;
				width: 198px;
				border-radius: 10px;
				line-height: 1.1em;
				padding: 12px 20px;
				left: 50%;
				transform: translate(-50%, 60%);
				font-size: 27.8057px !important;
				font-size: 2.78057rem !important;

				@media (max-width: $b2){
					width: 130px;
					padding: 3px 8px;
					border-radius: 8px;
					font-size: 19.0692px !important;
					font-size: 1.90692rem !important;
				}
			}
		}
	}
}

.images-section{

	.container{
		max-width: 1619px;
		padding-right: 25px;
		padding-left: 25px;
	}

	.row{
		margin-right: -25px;
		margin-left: -25px;
	}

	.col-12{
		padding-right: 25px;
		padding-left: 25px;
		margin-bottom: 50px;
	}

	img{
		margin: 0 auto;
		border: 5px solid $pink;
		border-radius: 20px;
	}
}

.navigation-section{
	padding-top: 19px;
	padding-bottom: 160px;

	@media (max-width: $b3){
		padding-bottom: 14px;
	}

	.container{
		max-width: 1613px;
	}

	.btn-con{

		@media (max-width: $b3){
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	.btn{
		padding: 1px 28px;
		font-size: 27.8605px !important;
		font-size: 2.78605rem !important;
		letter-spacing: -.005em;
	}
}

.contact-us-section{
	border-top: 25px solid $purple;
	padding-top: 95px;
	padding-bottom: 211px;

	@media (max-width: $b3){
		border-top: none;
		padding-top: 39px;
		padding-bottom: 61px;
	}

	.container{
		max-width: 1651px;
		padding-right: 46.5px;
		padding-left: 46.5px;

		@media (max-width: $b3){
			padding-right: 38px;
			padding-left: 38px;
		}
	}

	.row{
		margin-right: -46.5px;
		margin-left: -46.5px;
		justify-content: center;

		@media (max-width: $b3){
			margin-right: -38px;
			margin-left: -38px;
		}
	}

	.col-12{
		padding-right: 46.5px;
		padding-left: 46.5px;

		@media (max-width: $b3){
			padding-right: 38px;
			padding-left: 38px;
		}
	}

	h1{
		@include font-size(75);
		letter-spacing: .009em;

		@media (max-width: $b2){
			@include font-size(32);
			text-align: center;
		}
	}

	h4{
		@include font-size(36);
		margin-bottom: .05em;

		@media (max-width: $b2){
			@include font-size(26.6317);
			margin-bottom: .1em;
		}
	}

	.title{
		margin-bottom: .9em;
	}

	.col-lg-4{
		display: flex;
		margin-bottom: 93px;

		@media (max-width: $b3){
			margin-bottom: 20px;
		}

		*{
			color: white;
		}

		.inner{
			background-color: $purple;
			border-radius: 30px;
			padding: 39px 20px 27px;
			text-align: center;
			width: 100%;
			position: relative;
			border: 2px solid $purple;
			transition: $time;

			a{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 30px;
			}

			&:hover,
			&:focus-visible{
				background-color: white;

				*{
					color: $purple!important;
				}

				svg{

					*{
						fill: $purple;
					}
				}
			}

			@media (max-width: $b3){
				padding: 35px 10px 22px;
			}

			*{
				transition: $time;
			}

			svg,
			img{
				margin: 0 auto .82em;
				transition: $time;

				*{
					transition: $time;
				}

				@media (max-width: $b3){
					width: 110px;
				}
			}

			span{
				@include font-size(29.6165);

				@media (max-width: $b2){
					@include font-size(21.9094);
				}

				@media (max-width: 400px){
					@include font-size(18);
				}
			}

			a{

				&:hover,
				&:focus-visible{
					color: $pink;
				}
			}
		}
	}

	.form-con{
		padding-top: 120px;

		@media (max-width: $b3){
			padding-right: 20px;
			padding-left: 20px;
		}

		h1{
			margin-bottom: 1.13em;

			@media (max-width: $b3){
				margin-bottom: .77em;
			}
		}

		.gform_fields{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			#field_2_15{
				width: 41.5%;
				padding-right: 28px;

				@media (max-width: $b3){
					width: 100%;
					padding-right: 0;
				}
			}

			#field_2_14{
				width: 58.5%;
				padding-left: 28px;

				@media (max-width: $b3){
					width: 100%;
					padding-left: 0;
				}
			}
		}

		input,
		input.input-text,
		textarea,
		select{
			margin-bottom: 34px;

			@media (max-width: $b3){
				margin-bottom: 22px;
			}
		}

		textarea {
			height: 359.5px;
			min-height: 359.5px;
		}

		.gform_footer{

			@media (max-width: $b3){
				text-align: center;
			}
		}

		[type="submit"]{
			margin-top: 10px;
			min-width: 368px;

			@media (max-width: $b2){
				min-width: 0;
				font-size: 20px!important;
				font-size: 2.0rem!important;
				padding: 2px 27px;
			}
		}
	}
}

.four-zero-four-section{
	background-color: $purple;
	text-align: center;
	padding-top: 62px;
	padding-bottom: 171px;

	@media (max-width: $b3){
		padding-bottom: 62px;
	}

	*{
		color: white;
	}

	h1{
		margin-bottom: .04em;
		@include font-size(318.6688);

		@media (max-width: $b1){
			@include font-size(275);
		}

		@media (max-width: $b2){
			@include font-size(250);
		}

		@media (max-width: $b3){
			@include font-size(125);
		}
	}

	p{

		font-weight: 700;
		letter-spacing: .001em;
		margin-bottom: 1.1em;
		@include font-size(62.6016);

		@media (max-width: $b1){
			@include font-size(55);
		}

		@media (max-width: $b2){
			@include font-size(45);
		}

		@media (max-width: $b3){
			@include font-size(30);
		}
	}

	.btn{
		text-transform: none;
		min-width: 294px;

		&:hover,
		&:focus-visible{
			background-color: $purple;
			border-color: $white;
			color: $white!important;
		}
	}
}

.alternating-text-section{

	.container{
		max-width: 1501px;
	}

	.outside{
		padding-top: 122px;
		padding-bottom: 0;

		@media (max-width: $b3){
			padding-top: 50px;
		}

		&:nth-of-type(even){
			background-image: url(../images/joy-back.jpg);
			background-repeat: no-repeat;
			background-position: 50% 23%;
			background-size: 1976px 1150px;
			padding: 50px 0;

			@media (max-width: $b3){
				padding: 50px 0;
				background-position: 50% 50%;
				background-size: auto 100%;
			}

			+ .outside{
				padding-top: 0;

				&:last-of-type{

				}
			}
		}
	}

	h3{
		letter-spacing: .009em;
		margin-bottom: .5em;

		@media (min-width: $b2 + 1){
			@include font-size(36);
		}
	}

	ul{

		li{
			padding-left: 76px;
			letter-spacing: -.009em;
			margin-bottom: 2.05em;

			@media (max-width: $b2){
				padding-left: 47px;
				text-align: left;
			}

			&:before{
				background-color: transparent;
				width: 57.4043px;
				height: 62.3895px;
				background-size: 100% auto;
				background-image: url(../images/heart-bullet.svg);
				background-repeat: no-repeat;
				border-radius: 0;
				left: 0.21em;
				top: 0.08em;

				@media (max-width: $b2){
					width: 32px;
					height: 35px;
					background-size: 100% auto;
				}
			}
		}
	}
	section.alternating-text-section img {
		margin-top: 0;
	}
}

.two-column-section{
	padding-top: 50px;
	padding-bottom: 50px;

	.container{
		max-width: 1501px;
	}

	h3{
		letter-spacing: .009em;
		margin-bottom: .5em;

		@media (min-width: $b2 + 1){
			@include font-size(36);
		}
	}

	ul{

		li{
			padding-left: 76px;
			letter-spacing: -.009em;
			margin-bottom: 2.05em;

			@media (max-width: $b2){
				padding-left: 47px;
				text-align: left;
			}

			&:before{
				background-color: transparent;
				width: 57.4043px;
				height: 62.3895px;
				background-size: 100% auto;
				background-image: url(../images/heart-bullet.svg);
				background-repeat: no-repeat;
				border-radius: 0;
				left: 0.21em;
				top: 0.08em;

				@media (max-width: $b2){
					width: 32px;
					height: 35px;
					background-size: 100% auto;
				}
			}
		}
	}
}


.introduction-section + .links-section{
	margin-top: -37px;

	@media (max-width: $b3){
		margin-top: 0;
	}
}

.center-hero-section + .links-section{
	padding-top: 127px;

	@media (max-width: $b3){
		padding-top: 50px;
	}
}

.links-section{
	padding-bottom: 100px;

	@media (max-width: $b3){
		padding-bottom: 50px;
	}

	.container{
		max-width: 1618px;
		padding-right: 18.5px;
		padding-left: 18.5px;
	}

	.row{
		margin-right: -18.5px;
		margin-left: -18.5px;
	}

	.col-12{
		padding-right: 18.5px;
		padding-left: 18.5px;
		display: flex;
		margin-bottom: 37px;
	}

	.btn{
		min-width: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 35.5499px !important;
		font-size: 3.55499rem !important;
		line-height: 1.19;
		padding: 28px 26px;

		@media (max-width: $b2){
			font-size: 22px !important;
			font-size: 2.2rem !important;
		}
	}
}

.log-in-customer-section{
	position: relative;

	@media (min-width: $b3 + 1){
		&:before{
			content: '';
			top: 0;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			height: 25px;
			width: 300%;
			max-width: 300%;
			background-color: $purple;
			z-index: 1;
		}
	}


	@media (max-width: $b3){
		border-top: none;
	}

	.woocommerce &{
		width: 100vw;
		max-width: 100vw;
		margin-left: calc((100vw - #{$base-width}) / -2);
	}

	h3{
		color: $blue;
		margin-bottom: .4em;

		@media (min-width: $b2 + 1){
			@include font-size(36);
		}
	}

	p{
		margin-bottom: 1.1em;
	}


	.col-md-7.col-md-7.col-md-7.col-md-7.col-md-7.col-md-7{
		padding-top: 96px;
		padding-bottom: 80px;

		@media (max-width: $b3){
			padding-top: 50px;
			padding-bottom: 50px;
		}

		@media (min-width: $b2 + 1){
			padding-right: 128px;
			padding-left: 183px;
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 60.25%;
			max-width: 60.25%;
		}

		form{
			display: flex;
			flex-wrap: wrap;

			input,
			input.input-text,
			textarea,
			select{
				margin-bottom: 24px;
			}

			.lost_password,
			.form-row,
			.form-row-first,
			.form-row-last{
				width: 100%;
				margin-bottom: 0;

				[type="submit"]{
					margin-bottom: 0;
					width: auto;
					cursor: pointer;
					padding: 7px 9px;
					//font-size: 34.1316px !important;
					//font-size: 3.41316rem !important;
					min-width: 210px;
					border-radius: 8px;
					border-color: $purple;
					background-color: $purple;
					min-width: 231px;
					margin-top: 24px;
					margin-bottom: 44px;

					&:hover,
					&:focus-visible{
						background-color: transparent;
						border-color: $purple;
						color: $purple!important;
					}
				}
			}

			.lost_password{
				display: flex;
				flex-wrap: wrap;

				label{
					margin-bottom: 0;
					margin-right: 33px;
				}

				a{
					line-height: 46px;
					margin-bottom: 5px;
				}

				input[type="checkbox"]{

					& + span{
						padding-right: 65px !important;
						padding-left: 0 !important;
						@include font-size(29.3484);
						font-weight: 700;
						line-height: 46px;

						@media (max-width: $b3){
							@include font-size(17);
						}

						&:before{
							width: 46px;
							height: 46px;
							left: 0;
							top: 2px;
							border: 2px solid $purple;
							left: auto;
							right: 0;
							border-radius: 46px;
						}

						&:after{
							width: 30px;
							height: 30px;
							left: auto;
							top: 10px;
							right: 8px;
							border-radius: 46px;
						}
					}
				}
			}
		}
	}

	.col-md-5{
		position: relative;
		padding-top: 96px;
		padding-bottom: 96px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $blue;
		text-align: center;

		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			max-width: 100vw;
			height: 100%;
			background-color: $blue;
			z-index: -1;
		}

		@media (max-width: $b3){
			padding-top: 50px;
			padding-bottom: 50px;
		}

		*{
			color: white;
		}

		h1{
			margin-bottom: .6em;
		}

		.btn{
			padding: 7px 9px;
			@include font-size(22);
			border-radius: 8px;
			min-width: 231px;
			margin-top: 30px;

			&:hover,
			&:focus-visible{
				background-color: $blue;
				border-color: $white;
				color: $white!important;
			}
		}

		@media (min-width: $b3 + 1){
			flex: 0 0 39.75%;
			max-width: 39.75%;
		}
	}
}

#content.widecolumn{
	//border-top: 25px solid $purple;
	padding-top: 122px;
	padding-bottom: 155px;
	max-width: 1602px;
	margin: 0 auto;
	position: relative;
	padding-right: 20.5px;
	padding-left: 20.5px;

	@media (max-width: $b3){
		padding-right: 16px;
		padding-left: 16px;
	}

	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 100vw;
		max-width: 100vw;
		height: 25px;
		background-color: $purple;
	}

	h1{
		@include font-size(75);
		letter-spacing: .008em;
		margin-bottom: .35em;
	}

	h3{
		@include font-size(48.9139);
		color: $blue;
		margin-bottom: .4em;
	}

	p{
		margin-bottom: 1.1em;
		letter-spacing: 0;
	}

	.container {

	}
}

.register-customer-section{
	border-top: 25px solid $purple;
	padding: 25px 0;

	@media (max-width: $b3){
		border-top: none;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	img{
		margin-top: 0;
	}

	.clearfix{
		width: 100%;
	}

	&.register-trade{

		.gform_wrapper{
			margin-top: 3.7em;
		}
	}

	.par-con{
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@media (max-width: $b3){
			justify-content: center;
		}

		.p-img{

			@media (min-width: $b3 + 1){
				width: 300px;
				margin-right: 1.95em;
			}
		}

		p{
			@media (min-width: $b3 + 1){
				width: calc(100% - 320px - 1.95em);
			}
		}
	}

	h1{
		letter-spacing: .008em;
		margin-bottom: .35em;

		@media (min-width: $b2 + 1){
			@include font-size(48);
		}
	}

	h3{
		color: $blue;
		margin-bottom: .4em;

		@media (min-width: $b2 + 1){
			@include font-size(36);
		}
	}

	p{
		margin-bottom: 1.1em;
		letter-spacing: 0;
	}

	.container {
		max-width: 1602px;
	}

	.gform_wrapper{
		margin-top: 4.5em;

		[type="submit"]{
			min-width: 360px;
		}
	}

	.gform_fields{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.gfield--width-full{
		width: 100%;
	}

	.gfield--width-half{
		width: calc(50% - 38px);

		@media (max-width: $b3){
			width: 100%;
		}
	}

	.gform_fields{

		.ginput_complex{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			> span{
				width: calc(50% - 38px);

				@media (max-width: $b3){
					width: 100%;
				}
			}

			.gf_clear{
				width: 100%;
			}
		}

		input,
		input.input-text,
		textarea,
		select{
			margin-bottom: 34px;
		}

		input[type="radio"],
		input[type="checkbox"]{

			+ label:before,
			+ label:after{
				border-radius: 33px;
			}
		}

		.ginput_container_consent{
			margin-top: -3px;
			margin-bottom: 28px;
		}

		fieldset{
			padding: 0;
			border: none;

			legend{
				display: none
			}

			.validation_message{
				bottom: 15px;
			}
		}
	}
}

.shop-section.shop-section.shop-section.shop-section.shop-section.shop-section.shop-section{
	border-top: 10px solid $purple;
	padding-top: 92px;
	padding-bottom: 155px;
	position: relative;

	@media (max-width: $b3){
		border-top: none;
		padding-top: 42px;
		padding-bottom: 80px;
	}

	.container{
		max-width: 1754px;
	}

	.container-fluid,
	.container,
	.col-12{
		padding-right: 16px;
		padding-left: 16px;
	}

	.row{
		margin-right: -16px;
		margin-left: -16px;
	}


	.col-12.title{

		@media (min-width: $b3 + 1){
			flex: 0 0 67.4%;
			max-width: 67.4%;
			margin-left: 32.6%;
		}

		@media (max-width: $b3){
			text-align: center;
		}

		h1{
			@include font-size(36);
			text-transform: capitalize;

			@media (max-width: $b2){
				@include font-size(32);
			}
		}
	}

	h3{
		@include font-size(22);
		margin-bottom: .4em;

		@media (max-width: $b2){
			font-size: 32px!important;
			font-size: 3.2rem!important;
		}

		@media (max-width: $b3){
			font-size: 0px!important;
			font-size: 0rem!important;
			background-image: url(../images/filter.svg);
			background-repeat: no-repeat;
			width: 51.1201px;
			height: 47.7627px;
			margin: 3px auto 25px;

			&.open + .yith-wcan-filters{
				max-height: 9999px;
			}
		}
	}

	.filter-side{
		position: static;

		&.empty{

			h3{
				display: none;
			}
		}


		@media (min-width: $b3 + 1){
			flex: 0 0 32.6%;
			max-width: 32.6%;
		}
	}

	.yith-wcan-filters.yith-wcan-filters.yith-wcan-filters.yith-wcan-filters{

		@media (max-width: $b3){
			border: 3px solid $purple;
			border-top: none;

			.filters-container{
				padding: 25px 14px 10px;
			}
		}

		.top{
			border-bottom: 3px solid $purple;
			@include font-size(22.838);
			padding: 16px 70px 16px 19px;
			position: relative;

			+ .top{
				display: none;
			}

			.close{
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				width: 57px;
				border-left: 3px solid $purple;
				cursor: pointer;

				&:before,
				&:after{
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					width: 30px;
					height: 2px;
					background-color: $purple;
				}

				&:before{
					transform: translate(-50%, -50%) rotate(50deg);
				}

				&:after{
					transform: translate(-50%, -50%) rotate(-50deg);
				}
			}


			@media (min-width: $b3 + 1){
				display: none;
			}


		}
	}

	.yith-wcan-filters{

		@media (max-width: $b3){
			max-height: 0;
			overflow: hidden;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			z-index: 3;
			background-color: white;
		}
	}

	.yith-wcan-filter.yith-wcan-filter.yith-wcan-filter.yith-wcan-filter{
		margin-bottom: 0px;

		h4{
			@include font-size(20);
			color: $purple;
			margin-bottom: .1em;
			background-image: url(../images/f-plus.svg);
			background-repeat: no-repeat;
			background-position: 100% .27em;
			cursor: pointer;
			padding-right: 28px;

			@media (max-width: $b2){
				@include font-size(17);
				text-transform: uppercase;
				background-size: 17px auto;
				background-position: 100% .2em;
				padding-right: 20px;
			}

			&.open{
				background-image: url(../images/f-minus.svg);

				+ .filter-content{
					max-height: 9999px;
				}
			}
		}

		.filter-content{
			max-height: 0;
			overflow: hidden;
		}

		.filter-items{
			margin-bottom: 43px;

			@media (max-width: $b3){
				margin-bottom: 27px;
			}

			li{
				line-height: 1.3;
				margin-bottom: 0;
				@include font-size(22);

				@media (max-width: $b2){
					@include font-size(17);
					text-transform: uppercase;
				}

				&.active{

					a{
						font-weight: 700;
					}
				}

				*{
					line-height: 1.3;
					@include font-size(20);

					@media (max-width: $b2){
						@include font-size(17);
						text-transform: uppercase;
					}
				}

				label{
					margin-bottom: 0;
					text-align: left;
				}

				a{
					font-weight: 400;
					color: $purple;
				}
			}
		}
	}

	.shop-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 67.4%;
			max-width: 67.4%;
		}

		.container-fluid{
			padding-right: 0;
			padding-left: 0;
		}
	}

	#products{

		@media (max-width: $b3){

			.product-normal{

				&:nth-of-type(2n + 1){
					padding-right: 6px;
				}

				&:nth-of-type(2n + 2){
					padding-left: 6px;
				}
			}
		}
	}

	.product-normal{
		margin-bottom: 80px;

		@media (max-width: $b3){
			margin-bottom: 39px;
		}

		@media (max-width: $b2){
			flex: 0 0 50%;
			max-width: 50%;
		}
	}

	.pagination-con{
		margin-top: -30px;
	}
}

.product-normal{
	display: flex;

	.inner{
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;

		> a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			&:hover,
			&:focus-visible{

				~ .bottom{

					h6{
						color: $purple;
					}
				}
			}
		}
	}

	.sale-icon{
		position: absolute;
		top: 10px;
		right: 10px;
		color: white;
		width: 60px;
		height: 60px;
		border-radius: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $purple;
		z-index: 1;
		@include font-size(16);

		@media (max-width: $b2){
			width: 48px;
			height: 48px;
			@include font-size(13);
		}
	}

	.img-con{
		border: 2px solid $grey-light;
		//height: 311px;
		padding-top: 86.954%;
		margin-bottom: 16px;

		@media (max-width: $b3){
			margin-bottom: 3px;
		}
	}

	.bottom{
		padding: 0 7px;

		@media (max-width: $b3){
			padding: 0 3px;
		}
	}

	h6{
		@include font-size(23);
		@include line-height(23,29.4449);
		color: #1d1d1b;
		font-weight: 400;
		margin-bottom: .85em;
		transition: $time;
		width: 100%;

		@media (max-width: $b2){
			@include font-size(12);
			margin-bottom: .75em;
		}
	}

	.bottom{
		flex-grow: 1;
		display: flex;
		flex-wrap: wrap;
	}

	.prices{
		display: inline-block;
		padding-right: 71px;
		//background-image: url(../images/cp-icon.svg);
		background-repeat: no-repeat;
		background-position: 100% .25em;
		align-self: flex-end;
		position: relative;

		@media (max-width: $b2){
			padding-right: 36px;
			background-size: 22px;
		}

		.inc{

			&.sale{

				.woocommerce-Price-amount{

					&:nth-of-type(1){
						color: #b1b1b1;
						text-decoration: line-through;
						@include font-size(16.8638);
					}
				}
			}
		}

		.add{
			position: absolute;
			background-image: url(../images/cp-icon.svg);
			width: 45px;
			height: 45px;
			border-radius: 45px;
			background-size: 100% auto;
			position: absolute;
			right: 0;
			top: .25em;
			z-index: 2;

			&:hover,
			&:focus-visible{
				opacity: .75;
			}

			@media (max-width: $b2){
				width: 22px;
				height: 22px;
			}
		}
	}

	.inc,
	.ext{
		line-height: 1.1;
	}

	.inc{
		@include font-size(28.6818);
		color: #1d1d1b;
		margin-bottom: .2em;

		@media (max-width: $b2){
			@include font-size(15);
		}
	}

	.ext{
		@include font-size(16.8638);
		color: #b1b1b1;

		@media (max-width: $b2){
			@include font-size(9);
		}
	}
}

.single-product{

	.woocommerce-breadcrumb{
		display: none;
	}

	.woocommerce-tabs{
		display: none;
	}
}

.product-single-section{
	background-color: $purple;
	padding-top: 67px;
	padding-bottom: 89px;
	position: relative;

	@media (max-width: $b3){
		padding-top: 8px;
		padding-bottom: 110px;
	}

	&:after{
		content: '';
		position: absolute;
		bottom: 0;
		right: 30px;
		background-image: url(../images/hero-leaf.png);
		background-repeat: no-repeat;
		width: 329px;
		height: 441px;
		background-position: 100% 100%;
		background-size: 100% auto;
		z-index: 0;

		@media (max-width: $b3){
			display: none;
		}
	}

	*{
		color: white;
	}

	.container{
		max-width: 1759px;
	}

	.image-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 40.35%;
			max-width: 40.35%;
		}

		@media (max-width: $b3){
			margin-bottom: 42px;
		}

		.product-slide{
			width: 84.2%;

			@media (max-width: $b3){
				width: 100%;
			}

			.img-con{
				position: relative;
				display: inline-block;
				border-radius: 20px;
				overflow: hidden;

				&:after{
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					border-radius: 12px;
					border: 3px solid $pink;
					height: calc(100% - 30px);
					width: calc(100% - 30px);
				}
			}
		}

		.product-nav-slide{
			width: calc(84.2% + 10px + 10px);
			max-width: calc(84.2% + 10px + 10px);
			margin: 13px -10px 0;

			@media (max-width: $b3){
				width: calc(100% + 10px + 10px);
				max-width: calc(100% + 10px + 10px);
			}

			&.no-move{

				.slick-track{
					transform: translate3d(0px, 0px, 0px)!important;
				}
			}

			.slick-slide{
				margin: 0 10px;
			}

			img{
				width: 100%;
				border-radius: 10px;
				border: 3px solid $pink;
				cursor: pointer;
			}
		}

		.btn{
			margin-top: 25px;

			@media (min-width: $b3 + 1){
				margin-bottom: -25px;
				padding-right: 29px;
				padding-left: 29px;

			}

			&:hover,
			&:focus-visible{
				background-color: transparent;
				border-color: $white;
				color: $white!important;
			}

		}
	}

	.btn.btn.btn.btn.btn.btn.btn.btn.btn.btn.btn.btn,
	.button.button.button.button.button.button.button.button.button.button{

		&:hover,
		&:focus-visible{
			background-color: transparent;
			border-color: $white;
			color: $white!important;
		}
	}

	.info-side{

		@media (min-width: $b3 + 1){
			flex: 0 0 53.6%;
			max-width: 53.6%;
		}

		h1.product_title.product_title{
			margin-bottom: .27em;

			@media (min-width: $b3 + 1){
				margin-top: -.05em;
			}

			@media (max-width: $b2){
				@include font-size(25);
				margin-bottom: .47em;
			}
		}

		h4{
			@include font-size(43);

			@media (max-width: $b2){
				@include font-size(20);
			}
		}

		hr{
			margin: .9em 0 .6em;

			@media (max-width: $b3){
				margin: .9em 0 .95em;
			}
		}

		p{

			@media (max-width: $b2){
				@include font-size(14);
			}
		}

		.product_meta{
			display: none;
		}
	}

	.price-info{
		display: flex;
		flex-wrap: wrap;
		width: 98.5%;
		margin-top: 2.5em;

		@media (max-width: $b3){
			margin-top: .8em;
		}

		.prices{
			display: inline-block;
			background-repeat: no-repeat;
			background-position: 100% .25em;
			align-self: flex-end;
			padding-top: 11px;
			padding-bottom: 19px;

			@media (max-width: $b3){
				padding-top: 5px;
				padding-bottom: 11px;
			}

			.inc{

				&.sale{

					.woocommerce-Price-amount{

						&:nth-of-type(1){
							color: #b1b1b1;
							text-decoration: line-through;
							@include font-size(16.8638);

							@media (max-width: $b2){
								@include font-size(12.1239);
							}

							*{
								color: #b1b1b1;
							}
						}
					}
				}
			}
		}

		.inc,
		.ext{
			line-height: 1.1;
		}

		.inc{
			@include font-size(34.0158);
			color: #1d1d1b;
			margin-bottom: .2em;
			font-weight: 600;

			@media (max-width: $b2){
				@include font-size(20.6201);
			}
		}

		.ext{
			@include font-size(20);
			color: #b1b1b1;
			font-weight: 600;

			@media (max-width: $b2){
				@include font-size(12.1239);
			}

			*{
				color: #b1b1b1;
			}
		}

		.in-stock,
		.not-in-stock{
			@include font-size(19.8425);
			line-height: 30.1387px;
			background-position: 0 0;
			background-repeat: no-repeat;
			font-weight: 600;
			padding-left: 39px;
			margin-bottom: 1em;

			@media (max-width: $b2){
				@include font-size(12.0284);
				background-size: 19px auto;
				padding-left: 25px;
				line-height: 19px;
				margin-bottom: .9em;
			}
		}

		.in-stock{
			background-image: url(../images/in-stock.svg);
		}

		.not-in-stock{
			background-image: url(../images/not-in-stock.svg);
		}

		.price-side{
			width: 37.1%;

			@media (max-width: $b3){
				width: 113px;
			}
		}

		.info{
			width: 62.9%;
			border: 6px solid $pink;
			border-radius: 15px;
			z-index: 100;

			@media (max-width: $b3){
				width: calc(100% - 113px);
				border: 2px solid $pink;
				border-radius: 8px;
			}

			.shop_attributes.shop_attributes.shop_attributes.shop_attributes{
				display: block;
				padding: 12px 24px!important;
				margin: 0!important;

				@media (max-width: $b3){
					padding: 6px 10px!important;
				}

				tr,
				tbody{
					display: block;
				}

				th,
				td{
					@include font-size(25);
					padding: 0;
					border: none;
					background-color: transparent;

					@media (max-width: $b2){
						@include font-size(12);
					}

					p{
						@include font-size(25);
						padding: 0;
						display: inline;

						@media (max-width: $b2){
							@include font-size(12);
						}
					}

					&.woocommerce-product-attributes-item__value{
						font-style: normal;
						font-weight: 400;

						&:before{
							content: '- ';
							display: inline;
						}
					}
				}

				th{
					padding-right: .21em;
					font-weight: 700;
				}
			}
		}
	}

	.q-con.q-con.q-con{
		display: flex;
		align-items: center;
		margin-bottom: 18px;

		input.qty{
			margin-right: 5px;
			margin-bottom: 0;
		}

		#qty_dec,
		#qty_inc{
			font-size: 0;
			position: relative;
			border: 2px solid white;
			height: 37px;

			&:before,
			&:after{
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				width: 12px;
				height: 2px;
				background-color: $pink;
				transition: $time;
			}

			&:hover,
			&:focus-visible{
				background-color: transparent!important;

				&:before,
				&:after{
					background-color: white;
				}
			}
		}

		#qty_dec{

			&:before{
				transform: translate(-50%,-50%) rotate(0deg);
			}

			&:after{
				display: none;
			}
		}

		#qty_inc{

			&:before{
				transform: translate(-50%,-50%) rotate(0deg);
			}

			&:after{
				transform: translate(-50%,-50%) rotate(90deg);
			}
		}

		.lable{
			@include font-size(19.8425);
			font-weight: 600;

			@media (max-width: $b2){
				@include font-size(12.0284);
			}
		}
	}

	form.cart.cart.cart{
		margin-bottom: 0;
	}

	.button.button.button.button.button.button.button.button.button{
		background-color: $white;
		border-color: $white;
		color: $pink!important;
		text-transform: capitalize;
		font-size: 28.1316px !important;
		font-size: 2.81316rem !important;
		padding: 7px 18px;
		margin: 0!important;

		@media (max-width: $b2){
			font-size: 22px !important;
			font-size: 2.2rem !important;
			padding: 2px 18px;
		}

		@media (max-width: $b3){
			position: absolute;
			top: calc(100% + 24px);

		}

		&:hover,
		&:focus-visible{
			background-color: transparent;
			border-color: $pink;
			color: $pink!important;
		}
	}
}


div.quantity.quantity.quantity{

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type=number] {
		-moz-appearance: textfield;
	}

	input.qty{
		padding: 0;
		line-height: 33px;
		width: 35px;
		text-align: center;
		border: 2px solid white;
		@include font-size(19.8425);
		background-color: transparent;
		color: white;

		@media (max-width: $b2){
			line-height: 18px;
			width: 20px;
			@include font-size(12.0284);
		}
	}
}

.woocommerce-cart-form__cart-item.cart_item{

	div.quantity.quantity.quantity{

		input.qty{
			border-color: $grey-light;
			margin: 0 0;
			color: $purple;
			font-weight: 700;
		}
	}
}

.cross-sells{

	.related-products-con{
		padding: 0;
	}

	h2{
		padding-bottom: 10px;
	}

	.related-products{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 0 -21px;
		width: calc(100% + 21px + 21px);
		max-width: calc(100% + 21px + 21px);
		flex-wrap: wrap;

		.p-item{
			margin: 0 21px 42px;
			width: calc(50% - 21px - 21px);
		}
	}
}

.related-products-section{
	padding-top: 102px;
	padding-bottom: 71px;

	@media (max-width: $b3){
		padding-top: 39px;
		padding-bottom: 71px;
	}


	+ .related-products-section{
		display: none;
	}

	.title-con{
		text-align: center;
		margin-bottom: 1.7em;

		h2{
			color: $blue;
		}

		@media (max-width: $b3){
			margin-bottom: 1.1em;
		}
	}

	.products{
		width: 100%;
	}

	.related-products{
		display: flex;
		justify-content: center;
		margin: 0 -21px;
		width: calc(100% + 21px + 21px);
		max-width: calc(100% + 21px + 21px);
		flex-wrap: wrap;

		@media (max-width: $b3){
			margin: 0 -16px;
			width: calc(100% + 16px + 16px);
			max-width: calc(100% + 16px + 16px);
		}

		.p-item{
			margin: 0 21px 42px;
			width: calc(20% - 21px - 21px);

			@media (max-width: $b3){
				margin: 0 16px 24px;
				width: calc(50% - 16px - 16px);
			}
		}
	}
}

.p-item{

	.inner{
		position: relative;
		width: 100%;
		border-radius: 20px;
		border: 5px solid $pink;
		overflow: hidden;
		background-color: $pink;

		@media (max-width: $b3){
			border: 2px solid $pink;
			border-radius: 10px;
		}

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			&:hover,
			&:focus-visible{

				~ .bottom{
					background-color: rgba($blue, .6);
				}
			}
		}

		.img-con{
			padding-top: 100.2%;

			@media (max-width: $b3){
				padding-top: 101.2%;
			}
		}

		.bottom{
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			text-align: center;
			background-color: rgba($blue, .9);
			padding: 15px 23px 23px;
			transition: $time;

			@media (max-width: $b3){
				padding: 6px 12px 11px;
			}

			h5{
				color: white;
				@include font-size(24);
				margin: 0;

				@media (max-width: $b2){
					@include font-size(19.892);
				}
			}
		}
	}
}

.center-hero-section{
	background-color: $purple;
	text-align: center;
	padding-top: 153px;
	padding-bottom: 192px;

	@media (max-width: $b3){
		padding-top: 80px;
		padding-bottom: 80px;
	}

	*{
		color: white;
	}

	h1{
		@include font-size(128.9651);

		@media (max-width: $b1){
			@include font-size(100);
		}

		@media (max-width: $b2){
			@include font-size(60);
		}

		@media (max-width: $b3){
			@include font-size(50);
		}
	}

	h2{
		margin-bottom: .54em;

		@media (min-width: $b2 + 1){
			@include font-size(62.6016);
		}
	}

	p{
		margin-bottom: 1.1em;

		@media (min-width: $b2 + 1){
			@include font-size(35);
		}
	}

	.btn-con{
		padding-top: 24px;
	}

	.btn{
		text-transform: none;
		min-width: 294px;
	}
}

.page-template.page-template-template-thank-you{

	.woocommerce-order{
		display: none;
	}
}




























@import 'woocommerce';


@media (max-width: $b3){

	.container,
	.container-fluid,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: 16px;
		padding-left: 16px;
	}

	.row{
		margin-right: -16px;
		margin-left: -16px;
	}
}

.woocommerce-cart {
	.woocommerce.woocommerce.woocommerce.woocommerce.woocommerce.woocommerce .cart_totals table td, .woocommerce-page.woocommerce-page.woocommerce-page.woocommerce-page.woocommerce-page.woocommerce-page .cart_totals table td {
		width: auto!important;
	}
}