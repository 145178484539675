 @charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-family: "slick" !default;
$slick-loader-path: "../images/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: .75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: .25 !default;

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	}
	@else {
		@return url($slick-loader-path + $url);
	}
}

/* Slider */

.slick-list {
	.slick-loading & {
		background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
	}
}

/* Icons */
@if $slick-font-family == "slick" {
	@font-face {
		font-family: "slick";
		src: slick-font-url("slick.eot");
		src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
		font-weight: normal;
		font-style: normal;
	}
}

/* Arrows */

.slick-prev,
.slick-next{
	position: absolute;
	display: block;
	width: 30px;
	height: 60px;
	border-radius: 0px;
	border: none;
	line-height: 0;
	font-size: 0;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	transform: translate(0, -50%);
	padding: 0;
	outline: none;
	z-index: 2;
	transition: all $time 0s, background-size 0s 0s;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	opacity: 1;
	background-color: white;

	&:hover{

	}

	&.slick-disabled{
		pointer-events: none;
		opacity: .25;
	}
}

.slick-prev {
	left: 0px;
	background-image: url(../images/slide-prev.svg);
	background-position: 48% 50%;

	@media (max-width: 1300px){
		left: 0px;
	}

	&:hover{
		background-color: $red;
		background-image: url(../images/slide-prev-white.svg);
	}

	[dir="rtl"] & {
		left: auto;
		right: -68px;

		@media (max-width: 1230px){
			right: -38px;
		}
	}
}

.slick-next {
	right: 0px;
	background-image: url(../images/slide-next.svg);
	background-position: 52% 50%;

	@media (max-width: 1300px){
		right: 0px;
	}
	
	&:hover{
		background-color: $red;
		background-image: url(../images/slide-next-white.svg);
	}

	[dir="rtl"] & {
		left: 0;
		right: auto;
	}
}

/* Dots */

//.slick-dotted.slick-slider {
//	margin-bottom: 30px;
//}

.slick-dots {
	position: absolute;
	bottom: -84px;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	left: 50%;
	transform: translateX(-50%);
	height: 13px;
	width: 100%;

	@media (max-width: $b3){
		bottom: -9px;
	}

	li{
		position: relative;
		display: inline-block;
		height: 13px;
		width: 13px;
		border-radius: 14px;
		margin: 0 8px;
		padding: 0;
		cursor: pointer;
		display: inline-flex;
		justify-content: center;
		align-items: center;

		button{
			border: 0;
			display: block;
			height: 13px;
			width: 13px;
			border-radius: 13px;
			outline: none;
			line-height: 0px;
			cursor: pointer;
			font-size: 0;
			padding: 0;
			transition: $time;
			opacity: 1;
			cursor: pointer;
			background-color: rgba(white,.4);

			&:hover, &:focus-visible{
				outline: none;
				background-color: rgba(white,1);
			}
		}

		&.slick-active button{
			background-color: rgba(white,1);
		}
	}
}
